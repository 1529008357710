// /root/pwa-inapp/client/src/pages/Home.js
import { useState, useRef, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeSection, setActiveSection] = useState('home')
  const { user, loading, checkApprovalStatus, login, register } = useAuth()
  const [isApproved, setIsApproved] = useState(null)
  const [checkingApproval, setCheckingApproval] = useState(true)
  const navigate = useNavigate()
  
  // Состояния для форм входа и регистрации
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const [loginCaptchaValue, setLoginCaptchaValue] = useState(null)
  
  const [registerUsername, setRegisterUsername] = useState('')
  const [registerEmail, setRegisterEmail] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [registerError, setRegisterError] = useState('')
  const [registerCaptchaValue, setRegisterCaptchaValue] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState('');

  const [activeTab, setActiveTab] = useState('register')

  const homeRef = useRef(null)
  const aboutRef = useRef(null)
  const benefitsRef = useRef(null)
  const pricingRef = useRef(null)
  const contactRef = useRef(null)

  useEffect(() => {
    const checkStatus = async () => {
      if (user) {
        try {
          const status = await checkApprovalStatus()
          setIsApproved(status)
        } catch (error) {
          console.error('Error checking approval status:', error)
        }
      } else {
        setIsApproved(null)
      }
      setCheckingApproval(false)
    }

    if (!loading) {
      checkStatus()
    }
  }, [user, loading, checkApprovalStatus])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id)
          }
        })
      },
      { threshold: 0.5 }
    )

    const refs = [homeRef, aboutRef, benefitsRef, pricingRef, contactRef]
    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current)
      }
    })

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current)
        }
      })
    }
  }, [])

  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    setLoginError('')
    if (!loginCaptchaValue) {
      setLoginError('Пожалуйста, подтвердите, что вы не робот')
      return
    }
    try {
      await login(loginEmail, loginPassword, loginCaptchaValue)
      // Redirect или обновление UI после успешного входа
    } catch (err) {
      console.error('Ошибка при входе:', err)
      setLoginError('Не удалось войти. Возможно ваш аккаунт еще не одобрен.')
    }
  }

  const handleRegisterSubmit = async (e) => {
    e.preventDefault()
    setRegisterError('')
    if (!registerCaptchaValue) {
      setRegisterError('Пожалуйста, подтвердите, что вы не робот')
      return
    }
    if (registerPassword !== confirmPassword) {
      setRegisterError('Пароли не совпадают.')
      return
    }
    try {
      console.log('Submitting registration form')
      const result = await register(registerUsername, registerEmail, registerPassword, registerCaptchaValue)
      console.log('Registration result:', result)
      navigate('/')
    } catch (err) {
      console.error('Registration error:', err)
      if (err.response && err.response.data && err.response.data.error) {
        setRegisterError(err.response.data.error)
      } else {
        setRegisterError('Не удалось зарегистрироваться. Пожалуйста, попробуйте еще раз.')
      }
    }
  }

  if (loading || checkingApproval) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    )
  }

  if (user && isApproved) {
    return <Navigate to="/dashboard" />
  }

  if (user && isApproved === false) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
          <p className="font-bold">Ожидание подтверждения</p>
          <p>Ваша регистрация успешно завершена. Пожалуйста, ожидайте подтверждения от администратора.</p>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <header className="fixed top-0 left-0 right-0 bg-white bg-opacity-90 shadow-md z-50">
        <nav className="container mx-auto px-6 py-3">
          <ul className="flex justify-center space-x-8">
            {[
              { name: 'Главная', ref: homeRef },
              { name: 'О сервисе', ref: aboutRef },
              { name: 'Преимущества', ref: benefitsRef },
              { name: 'Тарифы', ref: pricingRef },
              { name: 'Контакты', ref: contactRef },
            ].map((item) => (
              <li key={item.name}>
                <button
                  onClick={() => scrollTo(item.ref)}
                  className={`text-lg font-medium transition-colors ${
                    activeSection === item.ref.current?.id
                      ? 'text-blue-600'
                      : 'text-gray-600 hover:text-blue-600'
                  }`}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <main className="pt-20">
        <section id="home" ref={homeRef} className="min-h-screen flex items-center justify-center text-center">
          <div className="container mx-auto px-6">
            <h1 className="text-5xl font-bold mb-6 text-gray-800">Создайте свое PWA приложение за минуты</h1>
            <p className="text-xl mb-8 text-gray-600">Простой и эффективный способ развивать ваш бизнес онлайн</p>
            <button onClick={() => setIsModalOpen(true)} className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors">
              Начать бесплатно
            </button>
          </div>
        </section>

        <section id="about" ref={aboutRef} className="py-20 bg-white">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">О нашем сервисе</h2>
            <div className="max-w-3xl mx-auto text-center">
              <p className="text-xl mb-8 text-gray-600">
                Мы предлагаем самый доступный и простой способ создать PWA приложение для арбитража и заработка онлайн. Наши инструменты позволяют создавать и настраивать приложения в несколько кликов, обеспечивая мгновенное создание, редактирование и возможность избежать блокировок.
              </p>
              <button onClick={() => setIsModalOpen(true)} className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors">
                Создать PWA прямо сейчас
              </button>
            </div>
            <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-blue-50 p-6 rounded-lg">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Почему именно мы?</h3>
                <p className="text-gray-600 mb-6">
                  Наша платформа предлагает уникальные возможности по созданию PWA-приложений с полной поддержкой на всех этапах. Мы работаем с любыми вертикалями, предоставляем инструменты для настройки дизайна и клоакинга.
                </p>
                <ul className="space-y-2">
                  {[
                    'Легкая интеграция и настройка PUSH-уведомлений',
                    'Встроенные инструменты для защиты и обхода блокировок',
                    'Возможность редактирования в реальном времени',
                    'Конструктор дизайна для создания уникальных приложений',
                  ].map((item, index) => (
                    <li key={index} className="flex items-center">
                      <span className="text-green-500 mr-2">✓</span>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-blue-50 p-6 rounded-lg">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Наши преимущества</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <span className="text-blue-500 mr-2 mt-1">⚡</span>
                    <div>
                      <h4 className="font-semibold">Молниеносная скорость загрузки</h4>
                      <p>Наши PWA загружаются в считанные секунды, обеспечивая отличный пользовательский опыт.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="text-blue-500 mr-2 mt-1">🛡️</span>
                    <div>
                      <h4 className="font-semibold">Выпуск сертификатов в личном кабинете</h4>
                      <p>Легко создавайте и управляйте SSL-сертификатами для ваших доменов прямо в панели управления.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="text-blue-500 mr-2 mt-1">⏱️</span>
                    <div>
                      <h4 className="font-semibold">Экономия на доменах</h4>
                      <p>Создавайте поддомены для оптимизации расходов и эффективного управления проектами.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <span className="text-blue-500 mr-2 mt-1">💰</span>
                    <div>
                      <h4 className="font-semibold">Самые низкие цены на рынке</h4>
                      <p>Мы предлагаем непревзойденное соотношение цены и качества для создания PWA.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="benefits" ref={benefitsRef} className="py-20 bg-gray-50">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">Преимущества использования PWA</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  title: 'Быстрое создание',
                  description: 'Создавайте PWA приложения за считанные минуты без сложного кодирования.',
                  icon: <span className="text-blue-500 w-12 h-12 mb-4">⚡</span>,
                },
                {
                  title: 'Push-уведомления',
                  description: 'Отправляйте персонализированные уведомления для повышения вовлеченности пользователей.',
                  icon: <span className="text-blue-500 w-12 h-12 mb-4">📨</span>,
                },
                {
                  title: 'Без банов',
                  description: 'Избегайте блокировок и банов, характерных для традиционных мобильных приложений.',
                  icon: <span className="text-blue-500 w-12 h-12 mb-4">✅</span>,
                },
                {
                  title: 'Уникальный дизайн',
                  description: 'Создавайте приложения с индивидуальным дизайном, отражающим ваш бренд.',
                  icon: <span className="text-blue-500 w-12 h-12 mb-4">🎨</span>,
                },
              ].map((item, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  {item.icon}
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              ))}
            </div>
            <div className="text-center mt-12">
              <button onClick={() => setIsModalOpen(true)} className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors">
                Попробовать бесплатно →
              </button>
            </div>
          </div>
        </section>

        <section id="pricing" ref={pricingRef} className="py-20 bg-white">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">Выберите свой тариф</h2>
            <div className="max-w-3xl mx-auto mb-12">
              <h3 className="text-2xl font-semibold mb-4 text-center text-gray-700">Общее для всех тарифов:</h3>
              <ul className="text-center text-gray-600">
                <li>Бесплатное подключение своих доменов*</li>
                <li>Отправка push-уведомлений до 10,000 штук в месяц на одно PWA</li>
              </ul>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                { name: 'Start', price: '$10', pwa: '1 PWA' },
                { name: 'Pro', price: '$45', pwa: '5 PWA' },
                { name: 'Business', price: '$96', pwa: '12 PWA' },
                { name: 'Enterprise', price: '$350', pwa: '50 PWA' },
              ].map((plan, index) => (
                <div key={index} className="bg-blue-50 p-6 rounded-lg shadow-md text-center">
                  <h3 className="text-2xl font-bold mb-4 text-gray-800">{plan.name}</h3>
                  <p className="text-4xl font-bold mb-6 text-blue-600">{plan.price}<span className="text-sm text-gray-600">/месяц</span></p>
                  <p className="mb-6 text-gray-600">{plan.pwa}</p>
                  <button onClick={() => setIsModalOpen(true)} className="w-full bg-transparent border border-blue-600 text-blue-600 py-2 px-4 rounded-md hover:bg-blue-600 hover:text-white transition-colors">
                    Выбрать
                  </button>
                </div>
              ))}
            </div>
            <p className="mt-8 text-center text-gray-600">* Вы также можете приобрести домены у нас. Стоимость одного домена $6.</p>
            <div className="text-center mt-12">
              <button onClick={() => setIsModalOpen(true)} className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors">
                Зарегистрироваться и начать бесплатно →
              </button>
            </div>
          </div>
        </section>

        <section id="contact" ref={contactRef} className="py-20 bg-gray-50">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold mb-8 text-gray-800">Свяжитесь с нами</h2>
            <p className="text-xl mb-8 text-gray-600">У вас есть вопросы? Мы всегда готовы помочь!</p>
            <a
              href="https://t.me/modeler111"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors inline-block"
            >
              Написать в Telegram →
            </a>
          </div>
        </section>
      </main>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg max-w-md w-full overflow-hidden">
            <div className="flex">
              <button
                onClick={() => {
                  setActiveTab('register')
                  setRegisterCaptchaValue(null) // Сброс ReCAPTCHA при переключении вкладок
                }}
                className={`py-2 w-1/2 text-sm font-medium ${
                  activeTab === 'register' 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                } transition-colors focus:outline-none`}
              >
                <span className="pl-4">Регистрация</span>
              </button>
              <button
                onClick={() => {
                  setActiveTab('login')
                  setLoginCaptchaValue(null) // Сброс ReCAPTCHA при переключении вкладок
                }}
                className={`py-2 w-1/2 text-sm font-medium ${
                  activeTab === 'login' 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                } transition-colors focus:outline-none`}
              >
                <span className="pl-4">Вход</span>
              </button>
            </div>
            <div className="p-6">
              {activeTab === 'register' ? (
                <form id="register-form" onSubmit={handleRegisterSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="register-username" className="block text-sm font-medium text-gray-700 mb-1">
                      Имя пользователя
                    </label>
                    <input
                      id="register-username"
                      type="text"
                      value={registerUsername}
                      onChange={(e) => setRegisterUsername(e.target.value)}
                      className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="register-email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      id="register-email"
                      type="email"
                      value={registerEmail}
                      onChange={(e) => setRegisterEmail(e.target.value)}
                      className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="register-password" className="block text-sm font-medium text-gray-700 mb-1">
                      Пароль
                    </label>
                    <input
                      id="register-password"
                      type="password"
                      value={registerPassword}
                      onChange={(e) => setRegisterPassword(e.target.value)}
                      className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="register-confirm-password" className="block text-sm font-medium text-gray-700 mb-1">
                      Подтвердите пароль
                    </label>
                    <input
                      id="register-confirm-password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <ReCAPTCHA
                    sitekey="6LcJ2CsqAAAAAMUzaxXQoMbzTi0oGDkE3S6Zemuz"
                    onChange={(value) => setRegisterCaptchaValue(value)}
                    className="mt-4"
                  />
                  {registerError && <p className="text-red-500 text-sm">{registerError}</p>}
                </form>
              ) : (
                <form id="login-form" onSubmit={handleLoginSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="login-email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      id="login-email"
                      type="email"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="login-password" className="block text-sm font-medium text-gray-700 mb-1">
                      Пароль
                    </label>
                    <input
                      id="login-password"
                      type="password"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <ReCAPTCHA
                    sitekey="6LcJ2CsqAAAAAMUzaxXQoMbzTi0oGDkE3S6Zemuz"
                    onChange={(value) => setLoginCaptchaValue(value)}
                    className="mt-4"
                  />
                  {loginError && <p className="text-red-500 text-sm">{loginError}</p>}
                </form>
              )}
            </div>
            <div className="px-6 pb-6 flex justify-between items-center">
              <button 
                type="submit" 
                form={activeTab === 'register' ? 'register-form' : 'login-form'}
                className="bg-blue-600 text-white py-2 px-4 rounded-md text-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
              >
                {activeTab === 'register' ? 'Зарегистрироваться' : 'Войти'}
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-100 text-gray-800 py-2 px-4 rounded-md text-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors"
              >
                Закрыть
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}
