// /root/pwa-inapp/client/src/pages/PostbackSettings.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PostbackForm from '../components/PostbackForm';

function PostbackSettings() {
  const [postbacks, setPostbacks] = useState([]);
  const [pwaList, setPwaList] = useState([]);
  const [selectedPwa, setSelectedPwa] = useState('');
  const [editingPostback, setEditingPostback] = useState(null);
  const [newPostback, setNewPostback] = useState({
    name: '',
    url: '',
    clickIdParam: 'clickid',
    campaignIdParam: 'campaign',
    eventParam: 'event',
    amountParam: 'amount',
    events: [],
    customEventName: ''
  });

  useEffect(() => {
    fetchPwaList();
    fetchPostbacks();
  }, []);

  const fetchPwaList = async () => {
    try {
      const response = await axios.get('/api/pwa-apps');
      setPwaList(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке списка PWA:', error);
    }
  };

  const fetchPostbacks = async () => {
    try {
      const response = await axios.get('/api/postbacks');
      setPostbacks(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке настроек постбэков:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingPostback) {
      setEditingPostback({ ...editingPostback, [name]: value });
    } else {
      setNewPostback({ ...newPostback, [name]: value });
    }
  };

  const handleEventsChange = (e) => {
    const value = Array.from(e.target.selectedOptions, option => option.value);
    if (editingPostback) {
      setEditingPostback({ ...editingPostback, events: value });
    } else {
      setNewPostback({ ...newPostback, events: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingPostback) {
        await axios.put(`/api/postbacks/${editingPostback._id}`, editingPostback);
        setEditingPostback(null);
      } else {
        await axios.post(`/api/postbacks/${selectedPwa}`, newPostback);
        setNewPostback({
          name: '',
          url: '',
          clickIdParam: 'clickid',
          campaignIdParam: 'campaign',
          eventParam: 'event',
          amountParam: 'amount',
          events: [],
          customEventName: ''
        });
      }
      fetchPostbacks();
    } catch (error) {
      console.error('Ошибка при сохранении настройки постбэка:', error);
    }
  };

  const handleEdit = (postback) => {
    setEditingPostback(postback);
  };

  const handleCancelEdit = () => {
    setEditingPostback(null);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Вы уверены, что хотите удалить эту настройку постбэка?')) {
      try {
        await axios.delete(`/api/postbacks/${id}`);
        fetchPostbacks();
      } catch (error) {
        console.error('Ошибка при удалении настройки постбэка:', error);
      }
    }
  };

  if (pwaList.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">Настройки постбэков</h1>
        <p>У вас пока нет созданных PWA приложений. Создайте PWA, чтобы настроить постбэки.</p>
      </div>
    );
  }

  if (postbacks.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">Настройки постбэков</h1>
        <p>Настройки постбэков пока отсутствуют. Выберите PWA и добавьте настройки.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Настройки постбэков</h1>
      
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">
          {editingPostback ? 'Редактирование настройки постбэка' : 'Добавление новой настройки постбэка'}
        </h2>
        <div className="mb-4">
          <label htmlFor="pwa-select" className="block text-sm font-medium text-gray-700">Выберите PWA</label>
          <select
            id="pwa-select"
            value={selectedPwa}
            onChange={(e) => setSelectedPwa(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Выберите PWA</option>
            {pwaList.length === 0 && (
              <option value="" disabled>Нет доступных PWA</option>
            )}
            {pwaList.map((pwa) => (
              <option key={pwa.pwaId} value={pwa.pwaId}>{pwa.name}</option>
            ))}
          </select>
        </div>
        <PostbackForm
          postback={editingPostback || newPostback}
          onChange={(e) => {
            if (e.target.name === 'events') {
              handleEventsChange(e);
            } else {
              handleInputChange(e);
            }
          }}
          onSubmit={handleSubmit}
        />
        {editingPostback && (
          <button
            onClick={handleCancelEdit}
            className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Отменить редактирование
          </button>
        )}
      </div>
      
      <h2 className="text-xl font-bold mb-4">Существующие настройки постбэков</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {postbacks.map(postback => (
          <div key={postback._id} className="bg-white shadow rounded-lg p-4">
            <h3 className="font-bold text-lg mb-2">{postback.name}</h3>
            <p className="mb-2">URL: {postback.url}</p>
            <p className="mb-2">События: {postback.events.join(', ')}</p>
            {postback.customEventName && <p className="mb-2">Пользовательское событие: {postback.customEventName}</p>}
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => handleEdit(postback)}
                className="py-1 px-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
              >
                Редактировать
              </button>
              <button
                onClick={() => handleDelete(postback._id)}
                className="py-1 px-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              >
                Удалить
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PostbackSettings;