// /root/pwa-inapp/client/src/pages/User/Profile.js

import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';

function Profile() {
  const { user, refreshToken } = useAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState(null);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setMessage(null);

    if (newPassword !== confirmNewPassword) {
      setMessage({ type: 'error', text: 'Новые пароли не совпадают' });
      return;
    }

    try {
      await axios.post('/api/user/change-password', { oldPassword, newPassword });
      setMessage({ type: 'success', text: 'Пароль успешно изменен' });
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      await refreshToken(); // Обновляем токен после смены пароля
    } catch (error) {
      setMessage({ type: 'error', text: error.response?.data?.error || 'Ошибка при смене пароля' });
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 px-4">
      <h1 className="text-2xl font-bold mb-4">Профиль пользователя</h1>
      <div className="mb-6">
        <p className="text-lg">Имя пользователя: {user?.username}</p>
        <p className="text-lg">Email: {user?.email}</p>
        <p className="text-lg">Баланс: ${user?.balance?.toFixed(2) || '0.00'}</p>
        {user?.currentTariff && (
          <>
            <p className="text-lg">Текущий тариф: {user.currentTariff.name}</p>
            <p className="text-lg">
              Действует до: {new Date(user.tariffExpiration).toLocaleDateString()}
            </p>
          </>
        )}
      </div>
      <h2 className="text-xl font-bold mb-4">Смена пароля</h2>
      <form onSubmit={handleChangePassword} className="space-y-4">
        <input
          type="password"
          placeholder="Текущий пароль"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
        <input
          type="password"
          placeholder="Новый пароль"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
        <input
          type="password"
          placeholder="Подтвердите новый пароль"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
        <button
          type="submit"
          className="w-full px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
        >
          Изменить пароль
        </button>
      </form>
      {message && (
        <div
          className={`mt-4 p-3 rounded ${
            message.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          }`}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Profile;
