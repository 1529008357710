// /root/pwa-inapp/client/src/pages/User/PWAApplications.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Импорт иконок из Heroicons
import { PencilIcon, TrashIcon, BellIcon, PaperAirplaneIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

function PWAApplications() {
  const [pwaApps, setPwaApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editData, setEditData] = useState({
    website: '',
    description: EditorState.createEmpty(),
    metrics: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchPWAApps();
  }, []);

  const fetchPWAApps = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://pwa-gen.com/api/pwa-apps', {
        withCredentials: true
      });
      setPwaApps(response.data);
      setError(null);
    } catch (error) {
      console.error('Ошибка при загрузке PWA приложений:', error);
      setError('Не удалось загрузить PWA приложения. Пожалуйста, попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (pwaId) => {
    if (window.confirm('Вы уверены, что хотите удалить это PWA приложение?')) {
      try {
        await axios.delete(`https://pwa-gen.com/api/pwa-apps/${pwaId}`, {
          withCredentials: true
        });
        fetchPWAApps();
      } catch (error) {
        console.error('Ошибка при удалении PWA:', error);
        setError('Не удалось удалить PWA приложение. Пожалуйста, попробуйте позже.');
      }
    }
  };

  const handleEdit = (app) => {
    const contentBlock = htmlToDraft(app.description);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setEditingId(app.pwaId);
    setEditData({ 
      website: app.website, 
      description: editorState,
      metrics: app.metrics || '',
    });
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditData({});
  };

  const handleSaveEdit = async (pwaId) => {
    try {
      const descriptionHtml = draftToHtml(convertToRaw(editData.description.getCurrentContent()));
      await axios.put(`https://pwa-gen.com/api/pwa-apps/update/${pwaId}`, {
        website: editData.website,
        description: descriptionHtml,
        metrics: editData.metrics,
      }, {
        withCredentials: true
      });
      fetchPWAApps();
      setEditingId(null);
      setEditData({});
    } catch (error) {
      console.error('Ошибка при обновлении PWA:', error);
      setError('Не удалось обновить PWA приложение. Пожалуйста, попробуйте позже.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData(prev => ({ ...prev, [name]: value }));
  };

  const handleEditorChange = (editorState) => {
    setEditData(prev => ({ ...prev, description: editorState }));
  };

  const handleNavigateToPushPage = (pwaId) => {
    navigate(`/goals?pwaId=${pwaId}`);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">PWA Приложения</h1>
      <Link
  to="/generate-pwa"
  className="inline-block px-4 py-2 bg-blue-600 !text-white rounded hover:bg-blue-700 transition duration-200 mb-8"
>
  Создать новое PWA
</Link>

      
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-2 px-4 text-left">Название приложения</th>
                <th className="py-2 px-4 text-left">Количество установок</th>
                <th className="py-2 px-4 text-left">Домен приложения</th>
                <th className="py-2 px-4 text-left">Push Subscriptions</th>
                <th className="py-2 px-4 text-left">Действия</th>
              </tr>
            </thead>
            <tbody>
              {pwaApps.map((app) => (
                <React.Fragment key={app.pwaId}>
                  <tr className="border-b">
                    <td className="py-2 px-4">{app.name}</td>
                    <td className="py-2 px-4">{app.installCount}</td>
                    <td className="py-2 px-4">
                      <a href={`https://${app.domain}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                        {app.domain}
                      </a>
                    </td>
                    <td className="py-2 px-4">
                      <div className="flex items-center">
                        <div className="flex items-center mr-2" title="Количество подписчиков">
                          <BellIcon className="h-5 w-5 text-gray-500" />
                          <span className="ml-1 text-sm text-gray-600">
                            {app.pushSubscriptions !== undefined ? app.pushSubscriptions : 'N/A'}
                          </span>
                        </div>
                        <button 
                          onClick={() => handleNavigateToPushPage(app.pwaId)} 
                          className="p-1 bg-blue-100 rounded-full hover:bg-blue-200 transition duration-200"
                          title="Перейти к управлению push-уведомлениями"
                        >
                          <PaperAirplaneIcon className="h-5 w-5 text-blue-600" />
                        </button>
                      </div>
                    </td>
                    <td className="py-2 px-4">
                      <button onClick={() => handleEdit(app)} className="mr-2 p-1 bg-yellow-100 rounded-full hover:bg-yellow-200 transition duration-200">
                        <PencilIcon className="h-5 w-5 text-yellow-600" />
                      </button>
                      <button 
                        onClick={() => handleDelete(app.pwaId)} 
                        className="p-1 bg-red-100 rounded-full hover:bg-red-200 transition duration-200"
                      >
                        <TrashIcon className="h-5 w-5 text-red-600" />
                      </button>
                    </td>
                  </tr>
                  {editingId === app.pwaId && (
                    <tr>
                      <td colSpan="5" className="py-4 px-4">
                        <div className="bg-gray-50 p-4 rounded-lg">
                          <h3 className="text-lg font-semibold mb-4">Редактирование</h3>
                          <input
                            type="text"
                            name="website"
                            value={editData.website || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded mb-4"
                            placeholder="Website"
                          />
                          <textarea
                            name="metrics"
                            value={editData.metrics || ''}
                            onChange={handleInputChange}
                            className="w-full p-2 border rounded mb-4"
                            rows="4"
                            placeholder="Metrics Code"
                          ></textarea>
                          <p className="mb-2">Описание</p>
                          <div className="border rounded p-2 mb-4">
                            <Editor
                              editorState={editData.description}
                              onEditorStateChange={handleEditorChange}
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                            />
                          </div>
                          <div className="flex space-x-2">
                            <button
                              onClick={() => handleSaveEdit(app.pwaId)}
                              className="flex items-center px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition duration-200"
                            >
                              <CheckIcon className="h-5 w-5 mr-2" />
                              Сохранить
                            </button>
                            <button
                              onClick={handleCancelEdit}
                              className="flex items-center px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition duration-200"
                            >
                              <XMarkIcon className="h-5 w-5 mr-2" />
                              Отмена
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default PWAApplications;