// /root/pwa-inapp/client/src/pages/User/MyDomains.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { 
  ChevronDownIcon, 
  ChevronUpIcon, 
  PlusIcon, 
  ArrowPathIcon, 
  CheckIcon, 
  LockClosedIcon, 
  TrashIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

const DomainStatus = {
  PENDING: 'Ожидание А-записи',
  A_RECORD_CONFIRMED: 'А-запись подтверждена',
  SSL_ISSUANCE: 'Выпуск HTTPS сертификата',
  READY: 'Готово',
  ERROR: 'Ошибка',
  SSL_ERROR: 'Ошибка выпуска SSL',
  SSL_EXPIRING: 'Сертификат истекает',
  SSL_EXPIRED: 'Сертификат истек'
};

function MyDomains() {
  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [expandedDomain, setExpandedDomain] = useState(null);
  const [deletingDomain, setDeletingDomain] = useState(null);
  const [deletingSubdomain, setDeletingSubdomain] = useState(null);
  const [domainError, setDomainError] = useState('');
  const [checkingARecord, setCheckingARecord] = useState({});
  const [newSubdomain, setNewSubdomain] = useState('');
  const [addingSubdomain, setAddingSubdomain] = useState({});
  const { user } = useAuth();

  useEffect(() => {
    fetchDomains();
  }, []);

  const fetchDomains = async () => {
    try {
      const response = await axios.get('/api/domains');
      setDomains(response.data);
    } catch (error) {
      console.error('Error fetching domains:', error);
      if (error.response && error.response.status === 401) {
        // Здесь можно добавить логику для обработки ошибки аутентификации
        // Например, перенаправление на страницу входа
        // history.push('/login');
      }
    }
  };

  const validateDomain = (domain) => {
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
    return domainRegex.test(domain);
  };

  const addDomain = async () => {
    if (!validateDomain(newDomain)) {
      setDomainError('Неверный формат домена. Пример правильного формата: example.com');
      return;
    }
    setDomainError('');
    try {
      const response = await axios.post('/api/domains', { domain: newDomain }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setDomains([...domains, response.data]);
      setNewDomain('');
    } catch (error) {
      console.error('Error adding domain:', error.response?.data || error.message);
      setDomainError(error.response?.data?.error || 'Ошибка при добавлении домена');
    }
  };

  const deleteDomain = async (domainId) => {
    try {
      await axios.delete(`/api/domains/${domainId}`);
      setDomains(domains.filter(domain => domain._id !== domainId));
      setDeletingDomain(null);
    } catch (error) {
      console.error('Error deleting domain:', error);
    }
  };

  const checkARecord = async (domainId) => {
    setCheckingARecord(prev => ({ ...prev, [domainId]: true }));
    try {
      const response = await axios.post(`/api/domains/${domainId}/check-a-record`);
      const updatedDomains = domains.map(domain => 
        domain._id === domainId ? { ...domain, status: response.data.status } : domain
      );
      setDomains(updatedDomains);
    } catch (error) {
      console.error('Error checking A record:', error);
    } finally {
      setCheckingARecord(prev => ({ ...prev, [domainId]: false }));
    }
  };

  const issueSSL = async (domainId, subdomainName = null) => {
    try {
      const url = subdomainName 
        ? `/api/domains/${domainId}/subdomains/${subdomainName}/issue-ssl`
        : `/api/domains/${domainId}/issue-ssl`;
      const response = await axios.post(url);
      const updatedDomains = domains.map(domain => {
        if (domain._id === domainId) {
          if (subdomainName) {
            const updatedSubdomains = domain.subdomains.map(subdomain =>
              subdomain.name === subdomainName ? { ...subdomain, status: response.data.status } : subdomain
            );
            return { ...domain, subdomains: updatedSubdomains };
          } else {
            return { ...domain, status: response.data.status };
          }
        }
        return domain;
      });
      setDomains(updatedDomains);
    } catch (error) {
      console.error('Error issuing SSL:', error);
    }
  };

  const checkSSLStatus = async (domainId, subdomainName = null) => {
    try {
      const url = subdomainName 
        ? `/api/domains/${domainId}/subdomains/${subdomainName}/ssl-status`
        : `/api/domains/${domainId}/ssl-status`;
      const response = await axios.get(url);
      const updatedDomains = domains.map(domain => {
        if (domain._id === domainId) {
          if (subdomainName) {
            const updatedSubdomains = domain.subdomains.map(subdomain =>
              subdomain.name === subdomainName ? { ...subdomain, status: response.data.status } : subdomain
            );
            return { ...domain, subdomains: updatedSubdomains };
          } else {
            return { ...domain, status: response.data.status };
          }
        }
        return domain;
      });
      setDomains(updatedDomains);
    } catch (error) {
      console.error('Error checking SSL status:', error);
    }
  };

  const addSubdomain = async (domainId) => {
    if (!newSubdomain) return;
    setAddingSubdomain(prev => ({ ...prev, [domainId]: true }));
    try {
      const response = await axios.post(`/api/domains/${domainId}/subdomains`, { subdomain: newSubdomain });
      const updatedDomains = domains.map(domain => 
        domain._id === domainId ? { ...domain, subdomains: [...domain.subdomains, response.data] } : domain
      );
      setDomains(updatedDomains);
      setNewSubdomain('');
    } catch (error) {
      console.error('Error adding subdomain:', error);
    } finally {
      setAddingSubdomain(prev => ({ ...prev, [domainId]: false }));
    }
  };

  const deleteSubdomain = async (domainId, subdomainName) => {
    try {
      await axios.delete(`/api/domains/${domainId}/subdomains/${subdomainName}`);
      const updatedDomains = domains.map(domain => {
        if (domain._id === domainId) {
          return {
            ...domain,
            subdomains: domain.subdomains.filter(sub => sub.name !== subdomainName)
          };
        }
        return domain;
      });
      setDomains(updatedDomains);
      setDeletingSubdomain(null);
    } catch (error) {
      console.error('Error deleting subdomain:', error);
    }
  };

  const handleExpandDomain = (domainId) => {
    setExpandedDomain(expandedDomain === domainId ? null : domainId);
  };

  const renderStatusChip = (status) => {
    const statusProps = {
      [DomainStatus.PENDING]: { color: 'bg-yellow-100 text-yellow-800', icon: <ArrowPathIcon className="w-4 h-4" /> },
      [DomainStatus.A_RECORD_CONFIRMED]: { color: 'bg-blue-100 text-blue-800', icon: <CheckIcon className="w-4 h-4" /> },
      [DomainStatus.SSL_ISSUANCE]: { color: 'bg-blue-100 text-blue-800', icon: <ArrowPathIcon className="w-4 h-4" /> },
      [DomainStatus.READY]: { color: 'bg-green-100 text-green-800', icon: <CheckIcon className="w-4 h-4" /> },
      [DomainStatus.ERROR]: { color: 'bg-red-100 text-red-800', icon: <XMarkIcon className="w-4 h-4" /> },
      [DomainStatus.SSL_ERROR]: { color: 'bg-red-100 text-red-800', icon: <XMarkIcon className="w-4 h-4" /> },
      [DomainStatus.SSL_EXPIRING]: { color: 'bg-yellow-100 text-yellow-800', icon: <ArrowPathIcon className="w-4 h-4" /> },
      [DomainStatus.SSL_EXPIRED]: { color: 'bg-red-100 text-red-800', icon: <XMarkIcon className="w-4 h-4" /> }
    };

    const { color, icon } = statusProps[status] || statusProps[DomainStatus.ERROR];

    return (
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${color}`}>
        {icon}
        <span className="ml-1">{status}</span>
      </span>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Мои домены</h1>
      
      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <p className="mb-4">
          Для добавления домена, пожалуйста, настройте A-запись вашего домена на IP-адрес нашего сервера: <strong>95.216.112.238</strong>
        </p>
        <p className="text-sm text-gray-600">
          После добавления домена, мы автоматически проверим A-запись и настроим HTTPS-сертификат для вашего домена.
        </p>
      </div>

      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Добавить новый домен (формат - example.com)</h2>
        <div className="flex items-center">
          <input
            type="text"
            className={`flex-grow p-2 border rounded-l ${domainError ? 'border-red-500' : 'border-gray-300'}`}
            placeholder="Введите домен"
            value={newDomain}
            onChange={(e) => setNewDomain(e.target.value)}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600 transition duration-200 flex items-center"
            onClick={addDomain}
            disabled={!newDomain}
          >
            <PlusIcon className="w-5 h-5 mr-1" />
            Добавить
          </button>
        </div>
        {domainError && <p className="text-red-500 text-sm mt-1">{domainError}</p>}
      </div>

      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Список доменов</h2>
        <ul>
          {domains.map((domain) => (
            <li key={domain._id} className="border-b last:border-b-0">
              <div className="flex items-center justify-between py-4">
                <div className="flex items-center">
                  <button 
                    onClick={() => handleExpandDomain(domain._id)}
                    className="mr-2 text-gray-500 hover:text-gray-700"
                  >
                    {expandedDomain === domain._id ? 
                      <ChevronUpIcon className="w-5 h-5" /> : 
                      <ChevronDownIcon className="w-5 h-5" />
                    }
                  </button>
                  <span className="font-medium">{domain.name}</span>
                </div>
                <div className="flex items-center space-x-2">
                  {renderStatusChip(domain.status)}
                  <button 
                    onClick={() => checkARecord(domain._id)}
                    disabled={domain.status !== DomainStatus.PENDING || checkingARecord[domain._id]}
                    className="p-1 text-blue-600 hover:text-blue-800 disabled:text-gray-400"
                    title="Проверить A-запись"
                  >
                    <CheckIcon className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={() => issueSSL(domain._id)}
                    disabled={domain.status !== DomainStatus.A_RECORD_CONFIRMED && 
                              domain.status !== DomainStatus.SSL_EXPIRED &&
                              domain.status !== DomainStatus.SSL_EXPIRING}
                    className="p-1 text-green-600 hover:text-green-800 disabled:text-gray-400"
                    title="Выпустить SSL"
                  >
                    <LockClosedIcon className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={() => checkSSLStatus(domain._id)}
                    disabled={domain.status === DomainStatus.PENDING || 
                              domain.status === DomainStatus.A_RECORD_CONFIRMED}
                    className="p-1 text-purple-600 hover:text-purple-800 disabled:text-gray-400"
                    title="Проверить статус SSL"
                  >
                    <ArrowPathIcon className="w-5 h-5" />
                  </button>
                  <button 
                    onClick={() => setDeletingDomain(domain._id)}
                    className="p-1 text-red-600 hover:text-red-800"
                    title="Удалить домен"
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
              {expandedDomain === domain._id && (
                <div className="pl-8 pb-4">
                  <ul className="space-y-2">
                    {domain.subdomains.map((subdomain) => (
                      <li key={`${domain._id}-${subdomain.name}`} className="flex items-center justify-between">
                        <span>{subdomain.name}</span>
                        <div className="flex items-center space-x-2">
                          {renderStatusChip(subdomain.status)}
                          <button 
                            onClick={() => issueSSL(domain._id, subdomain.name)}
                            disabled={subdomain.status !== DomainStatus.A_RECORD_CONFIRMED && 
                                      subdomain.status !== DomainStatus.SSL_EXPIRED &&
                                      subdomain.status !== DomainStatus.SSL_EXPIRING}
                            className="p-1 text-green-600 hover:text-green-800 disabled:text-gray-400"
                            title="Выпустить SSL"
                          >
                            <LockClosedIcon className="w-5 h-5" />
                          </button>
                          <button 
                            onClick={() => checkSSLStatus(domain._id, subdomain.name)}
                            disabled={subdomain.status === DomainStatus.PENDING || 
                                      subdomain.status === DomainStatus.A_RECORD_CONFIRMED}
                            className="p-1 text-purple-600 hover:text-purple-800 disabled:text-gray-400"
                            title="Проверить статус SSL"
                          >
                            <ArrowPathIcon className="w-5 h-5" />
                          </button>
                          <button 
                            onClick={() => setDeletingSubdomain({ domainId: domain._id, subdomain: subdomain.name })}
                            className="p-1 text-red-600 hover:text-red-800"
                            title="Удалить поддомен"
                          >
                            <TrashIcon className="w-5 h-5" />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-4 flex items-center">
                    <input
                      type="text"
                      placeholder="Добавить поддомен"
                      className="flex-grow p-2 border rounded-l border-gray-300"
                      value={newSubdomain}
                      onChange={(e) => setNewSubdomain(e.target.value)}
                      disabled={domain.status === DomainStatus.PENDING}
                    />
                    <button
                      onClick={() => addSubdomain(domain._id)}
                      disabled={domain.status === DomainStatus.PENDING || !newSubdomain || addingSubdomain[domain._id]}
                      className="bg-blue-500 text-white px-4 py-2 rounded-r hover:bg-blue-600 transition duration-200 disabled:bg-gray-400"
                    >
                      <PlusIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {deletingDomain && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">Подтвердите удаление</h3>
            <p className="mb-6">Вы уверены, что хотите удалить этот домен? Это действие нельзя отменить.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setDeletingDomain(null)}
                className="px-4 py-2 border rounded hover:bg-gray-100 transition duration-200"
              >
                Отмена
              </button>
              <button
                onClick={() => deleteDomain(deletingDomain)}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200"
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      )}

      {deletingSubdomain && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">Подтвердите удаление поддомена</h3>
            <p className="mb-6">Вы уверены, что хотите удалить этот поддомен? Это действие нельзя отменить.</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setDeletingSubdomain(null)}
                className="px-4 py-2 border rounded hover:bg-gray-100 transition duration-200"
              >
                Отмена
              </button>
              <button
                onClick={() => deleteSubdomain(deletingSubdomain.domainId, deletingSubdomain.subdomain)}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-200"
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyDomains;