import React, { useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { logToServer } from '../../logger';

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
}));

const logWithTimestamp = (message) => {
  const timestamp = new Date().toISOString();
  logToServer(`[${timestamp}] ${message}`);
};

const sendPostback = async (event, data) => {
  try {
    const response = await axios.get('/api/pwa');
    const { pwaId } = response.data;
    
    await axios.post(`/api/postbacks/incoming/${pwaId}`, {
      event,
      ...data
    });
    
    console.log(`Postback sent for event: ${event}`);
  } catch (error) {
    console.error('Error sending postback:', error);
  }
};

function InAppBrowser() {
  const checkAndSubscribe = useCallback(async () => {
    try {
      if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
        logWithTimestamp('Push notifications are not supported');
        return;
      }

      const registration = await navigator.serviceWorker.ready;
      let subscription = await registration.pushManager.getSubscription();

      if (subscription) {
        try {
          const response = await axios.post('/api/push/check-subscription', { endpoint: subscription.endpoint });
          if (response.data.valid) {
            logWithTimestamp('Existing subscription is valid');
            return;
          }
          logWithTimestamp('Existing subscription is invalid, unsubscribing');
          await subscription.unsubscribe();
        } catch (error) {
          logWithTimestamp(`Error checking subscription: ${error.message}`);
        }
      }

      const vapidResponse = await axios.get('/api/push/vapid-public-key');
      const vapidPublicKey = vapidResponse.data.publicKey;

      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        logWithTimestamp('Notification permission not granted');
        return;
      }

      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidPublicKey
      });

      const pwaResponse = await axios.get('/api/pwa');
      const { pwaId } = pwaResponse.data;

      await axios.post('/api/push/subscribe', { subscription, pwaId });
      logWithTimestamp('Successfully subscribed to push notifications');
    } catch (error) {
      logWithTimestamp(`Error in checkAndSubscribe: ${error.message}`);
    }
  }, []);

  useEffect(() => {
    const openInAppBrowser = async () => {
      logWithTimestamp('InAppBrowser: openInAppBrowser function called');
      try {
        const response = await axios.get('/api/pwa');
        logWithTimestamp('InAppBrowser: API response received');
        const { website } = response.data;
        logWithTimestamp(`InAppBrowser: website URL retrieved: ${website}`);

        const lastVisitedUrl = localStorage.getItem('lastVisitedUrl');
        const url = lastVisitedUrl || website;
        logWithTimestamp(`InAppBrowser: URL to open: ${url}`);
        
        window.location.href = url;

        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data.type === 'LOG_URL') {
              localStorage.setItem('lastVisitedUrl', event.data.url);
            }
          });

          navigator.serviceWorker.ready.then((registration) => {
            registration.active.postMessage({
              type: 'LOG_URL',
              url: window.location.href,
            });
          });
        }

        const logCurrentUrl = () => {
          const currentUrl = window.location.href;
          logWithTimestamp(`OnPageStarter: Current URL: ${currentUrl}`);
        };

        window.addEventListener('popstate', () => {
          logCurrentUrl();
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
              registration.active.postMessage({
                type: 'LOG_URL',
                url: window.location.href,
              });
            });
          }
        });

        const urlCheckInterval = setInterval(logCurrentUrl, 5000);

        setTimeout(() => {
          checkAndSubscribe();
        }, 1000);

        return () => {
          window.removeEventListener('popstate', () => {});
          clearInterval(urlCheckInterval);
        };
      } catch (error) {
        logWithTimestamp(`InAppBrowser: Error opening In-App Browser: ${error.message}`);
        axios.post('/log-error', { error: error.message });
      }
    };

    logWithTimestamp('InAppBrowser: useEffect called');
    openInAppBrowser();
  }, [checkAndSubscribe]);

  logWithTimestamp('InAppBrowser: rendered');
  return <Container />;
}

export default InAppBrowser;