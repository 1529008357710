// /root/pwa-inapp/client/src/components/PostbackForm.js
import React from 'react';

function PostbackForm({ postback, onChange, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="space-y-4">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Название</label>
        <input
          type="text"
          id="name"
          name="name"
          value={postback.name}
          onChange={onChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          required
        />
      </div>
      
      <div>
        <label htmlFor="url" className="block text-sm font-medium text-gray-700">URL постбэка</label>
        <input
          type="url"
          id="url"
          name="url"
          value={postback.url}
          onChange={onChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          required
        />
      </div>
      
      <div>
        <label htmlFor="clickIdParam" className="block text-sm font-medium text-gray-700">Параметр Click ID</label>
        <input
          type="text"
          id="clickIdParam"
          name="clickIdParam"
          value={postback.clickIdParam}
          onChange={onChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
      
      <div>
        <label htmlFor="campaignIdParam" className="block text-sm font-medium text-gray-700">Параметр Campaign ID</label>
        <input
          type="text"
          id="campaignIdParam"
          name="campaignIdParam"
          value={postback.campaignIdParam}
          onChange={onChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
      
      <div>
        <label htmlFor="eventParam" className="block text-sm font-medium text-gray-700">Параметр Event</label>
        <input
          type="text"
          id="eventParam"
          name="eventParam"
          value={postback.eventParam}
          onChange={onChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
      
      <div>
        <label htmlFor="amountParam" className="block text-sm font-medium text-gray-700">Параметр Amount</label>
        <input
          type="text"
          id="amountParam"
          name="amountParam"
          value={postback.amountParam}
          onChange={onChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
      
      <div>
        <label htmlFor="events" className="block text-sm font-medium text-gray-700">События</label>
        <select
          multiple
          id="events"
          name="events"
          value={postback.events}
          onChange={onChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value="registration">Регистрация</option>
          <option value="purchase">Покупка</option>
          <option value="custom">Пользовательское событие</option>
        </select>
      </div>
      
      {postback.events.includes('custom') && (
        <div>
          <label htmlFor="customEventName" className="block text-sm font-medium text-gray-700">Название пользовательского события</label>
          <input
            type="text"
            id="customEventName"
            name="customEventName"
            value={postback.customEventName}
            onChange={onChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      )}
      
      <div>
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Сохранить
        </button>
      </div>
    </form>
  );
}

export default PostbackForm;