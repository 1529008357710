// /root/pwa-inapp/client/src/pages/Admin/UsersList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UsersList() {
  const [users, setUsers] = useState([]);
  const [balanceUpdates, setBalanceUpdates] = useState({});

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/admin/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
    }
  };

  const toggleApproval = async (userId, currentStatus) => {
    try {
      await axios.post(`/api/admin/toggle-approval/${userId}`, { isApproved: !currentStatus });
      fetchUsers(); // Обновляем список пользователей
    } catch (error) {
      console.error('Ошибка при изменении статуса пользователя:', error);
    }
  };

  const handleBalanceChange = (userId, value) => {
    setBalanceUpdates({ ...balanceUpdates, [userId]: value });
  };

  const updateBalance = async (userId) => {
    try {
      const amount = parseFloat(balanceUpdates[userId]);
      await axios.post(`/api/admin/update-balance/${userId}`, { amount });
      setBalanceUpdates({ ...balanceUpdates, [userId]: '' });
      fetchUsers();
    } catch (error) {
      console.error('Ошибка при обновлении баланса пользователя:', error);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-2xl font-bold my-4">Управление пользователями</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Имя пользователя</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Статус</th>
              <th className="py-2 px-4 border-b">Кол-во PWA</th>
              <th className="py-2 px-4 border-b">Действия</th>
              <th className="py-2 px-4 border-b">Баланс</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id} className="text-center">
                <td className="py-2 px-4 border-b">{user.username}</td>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b">
                  <span
                    className={`inline-block px-2 py-1 rounded-full text-sm ${
                      user.isApproved ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'
                    }`}
                  >
                    {user.isApproved ? 'Одобрен' : 'Не одобрен'}
                  </span>
                </td>
                <td className="py-2 px-4 border-b">{user.pwaCount || 0}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => toggleApproval(user._id, user.isApproved)}
                    className={`px-3 py-1 rounded ${
                      user.isApproved
                        ? 'bg-red-500 hover:bg-red-600'
                        : 'bg-blue-500 hover:bg-blue-600'
                    } text-white`}
                  >
                    {user.isApproved ? 'Отменить одобрение' : 'Одобрить'}
                  </button>
                </td>
                <td className="py-2 px-4 border-b">
                  ${user.balance.toFixed(2)}
                  <div className="mt-2 flex items-center">
                    <input
                      type="number"
                      placeholder="Изменить баланс"
                      value={balanceUpdates[user._id] || ''}
                      onChange={(e) => handleBalanceChange(user._id, e.target.value)}
                      className="w-24 px-2 py-1 border border-gray-300 rounded mr-2"
                    />
                    <button
                      onClick={() => updateBalance(user._id)}
                      className="px-3 py-1 bg-green-500 hover:bg-green-600 text-white rounded"
                    >
                      Обновить
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UsersList;
