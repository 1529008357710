// /root/pwa-inapp/client/src/pages/User/TopUp.js
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';

function TopUp() {
  const { user, refreshUser } = useAuth();
  const [depositAddress, setDepositAddress] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && user.depositAddress) {
      setDepositAddress(user.depositAddress);
    } 
  }, [user]);

  const handleGenerateAddress = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/generate-deposit-address');
      setDepositAddress(response.data.depositAddress);
      await refreshUser(); // Обновляем данные пользователя в контексте
    } catch (error) {
      console.error('Ошибка при генерации адреса:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 text-center">
      <h1 className="text-4xl font-bold mb-4">Пополнение баланса</h1>
      <p className="text-lg mb-6">
        Отправьте USDT-TRC20 на адрес ниже для пополнения вашего баланса.
      </p>
      {depositAddress ? (
        <>
          <QRCodeCanvas value={depositAddress} size={200} />
          <p className="mt-4 font-mono text-xl">{depositAddress}</p>
        </>
      ) : (
        <>
          <p>У вас еще нет адреса для пополнения.</p>
          <button
            onClick={handleGenerateAddress}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            disabled={loading}
          >
            {loading ? 'Генерация адреса...' : 'Сгенерировать адрес для пополнения'}
          </button>
        </>
      )}
    </div>
  );
}

export default TopUp;
