// /root/pwa-inapp/client/src/pages/User/Goals.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip as ChartTooltip,
  Legend as ChartLegend
} from 'chart.js';
import {
  ChevronDownIcon,
  PaperAirplaneIcon,
  StopIcon,
  PlayIcon,
  PencilIcon
} from '@heroicons/react/24/outline';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  ChartTooltip,
  ChartLegend
);

export default function Goals() {
  const [pwaApps, setPwaApps] = useState([]);
  const [selectedPwa, setSelectedPwa] = useState('');
  const [notificationData, setNotificationData] = useState({
    title: '',
    body: '',
    icon: '',
    useCustomUrl: false,
    url: '',
    isRegular: false,
    frequency: 'daily',
    time: new Date(),
    customMinutes: 5,
    weekDays: [false, false, false, false, false, false, false],
    monthDay: 1,
  });
  const [campaigns, setCampaigns] = useState([]);
  const [stats, setStats] = useState({
    totalSent: 0,
    totalOpened: 0,
    totalSubscribers: 0
  });
  const [serverTime, setServerTime] = useState(new Date());
  const [chartData, setChartData] = useState(null);
  const [isRealData, setIsRealData] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [titleChars, setTitleChars] = useState(0);
  const [bodyChars, setBodyChars] = useState(0);
  const campaignsPerPage = 10;

  const [editingCampaign, setEditingCampaign] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editTime, setEditTime] = useState('');
  const [timeError, setTimeError] = useState('');
  const [openAccordion, setOpenAccordion] = useState(null);

  const addHours = (date, hours) => {
    const result = new Date(date);
    result.setHours(result.getHours() + hours);
    return result;
  };

  const formatLocalTime = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'Europe/Moscow' // Используем временную зону UTC+3 для отображения
    };
    return new Intl.DateTimeFormat('ru-RU', options).format(date);
  };

  const sendCampaign = async (campaignId) => {
    try {
      await axios.post(`/api/push/send/${campaignId}`);
      fetchCampaigns();
      fetchStats();
    } catch (error) {
      console.error('Error sending campaign:', error);
    }
  };

  const fetchPWAApps = useCallback(async () => {
    try {
      const response = await axios.get('/api/push/pwa-apps');
      setPwaApps(response.data);
    } catch (error) {
      console.error('Error fetching PWA apps:', error);
    }
  }, []);

  const fetchCampaigns = useCallback(async () => {
    try {
      const response = await axios.get('/api/push/campaigns');
      const sortedCampaigns = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setCampaigns(sortedCampaigns);
      setTotalPages(Math.ceil(sortedCampaigns.length / campaignsPerPage));
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const paginatedCampaigns = campaigns.slice((page - 1) * campaignsPerPage, page * campaignsPerPage);

  const fetchStats = useCallback(async () => {
    try {
      const response = await axios.get('/api/push/stats');
      setStats(response.data);
      if (response.data.chartData && response.data.chartData.labels.length > 0) {
        setChartData(response.data.chartData);
        setIsRealData(true);
      } else {
        setIsRealData(false);
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  }, []);

  const fetchServerTime = useCallback(async () => {
    try {
      const response = await axios.get('/api/push/server-time');
      const serverTimeUTC = new Date(response.data.serverTime);
      const serverTimeLocal = new Date(serverTimeUTC.getTime() + (serverTimeUTC.getTimezoneOffset() * 60000));
      setServerTime(serverTimeLocal);
    } catch (error) {
      console.error('Error fetching server time:', error);
    }
  }, []);

  useEffect(() => {
    fetchPWAApps();
    fetchCampaigns();
    fetchStats();
    fetchServerTime();

    const intervalId = setInterval(fetchServerTime, 60000);
    return () => clearInterval(intervalId);
  }, [fetchPWAApps, fetchCampaigns, fetchStats, fetchServerTime]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNotificationData(prev => ({ ...prev, [name]: value }));

    if (name === 'title') setTitleChars(value.length);
    if (name === 'body') setBodyChars(value.length);
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setNotificationData(prev => ({ ...prev, [name]: checked }));
  };

  const validateTime = (timeString) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timeRegex.test(timeString);
  };

  const formatTimeInput = (value) => {
    // Удаляем все нецифровые символы
    const cleanValue = value.replace(/\D/g, '');

    // Ограничиваем ввод четырьмя цифрами
    const truncatedValue = cleanValue.slice(0, 4);

    // Форматируем время
    if (truncatedValue.length <= 2) {
      return truncatedValue;
    } else {
      return `${truncatedValue.slice(0, 2)}:${truncatedValue.slice(2)}`;
    }
  };

  const handleTimeChange = (e) => {
    const formattedTime = formatTimeInput(e.target.value);
    if (validateTime(formattedTime)) {
      setTimeError('');
      const [hours, minutes] = formattedTime.split(':').map(Number);
      const newTime = new Date();
      newTime.setHours(hours, minutes, 0, 0);
      setNotificationData(prev => ({ ...prev, time: newTime }));
    } else {
      setTimeError('Пожалуйста, введите корректное время в формате ЧЧ:ММ');
    }
    // Обновляем значение в поле ввода
    e.target.value = formattedTime;
  };

  const handleEditTimeChange = (e) => {
    const formattedTime = formatTimeInput(e.target.value);
    setEditTime(formattedTime);
    if (validateTime(formattedTime)) {
      setTimeError('');
    } else {
      setTimeError('Пожалуйста, введите корректное время в формате ЧЧ:ММ');
    }
  };

  const handleWeekDayChange = (index) => {
    const newWeekDays = [...notificationData.weekDays];
    newWeekDays[index] = !newWeekDays[index];
    setNotificationData(prev => ({ ...prev, weekDays: newWeekDays }));
  };

  const handleMonthDayChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setNotificationData(prev => ({ ...prev, monthDay: newValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedPwa || !notificationData.title || !notificationData.body) {
      alert('Пожалуйста, заполните все обязательные поля.');
      return;
    }

    try {
      const campaignData = {
        ...notificationData,
        pwaId: selectedPwa
      };

      if (notificationData.isRegular) {
        if (notificationData.frequency !== 'minutes') {
          const utcTime = addHours(notificationData.time, 0);
          campaignData.time = utcTime.toISOString();
        }

        if (notificationData.frequency === 'minutes') {
          campaignData.customMinutes = parseInt(notificationData.customMinutes, 10);
        }

        if (notificationData.frequency === 'weekly') {
          campaignData.weekDays = notificationData.weekDays;
        }

        if (notificationData.frequency === 'monthly') {
          campaignData.monthDay = notificationData.monthDay;
        }

        await axios.post('/api/push/campaigns', campaignData);
      } else {
        await axios.post('/api/push/send-single', campaignData);
      }

      fetchCampaigns();
      fetchStats();
      setNotificationData({
        title: '',
        body: '',
        icon: '',
        useCustomUrl: false,
        url: '',
        isRegular: false,
        frequency: 'daily',
        time: new Date(),
        customMinutes: 5,
        weekDays: [false, false, false, false, false, false, false],
        monthDay: 1
      });
      setTitleChars(0);
      setBodyChars(0);
    } catch (error) {
      console.error('Error creating/sending campaign:', error);
    }
  };

  const handleStopCampaign = async (id) => {
    try {
      await axios.post(`/api/push/stop/${id}`);
      fetchCampaigns();
    } catch (error) {
      console.error('Error stopping campaign:', error);
    }
  };

  const handleStartCampaign = async (id) => {
    try {
      await axios.post(`/api/push/start/${id}`);
      fetchCampaigns();
    } catch (error) {
      console.error('Error starting campaign:', error);
    }
  };

  const handleDeleteCampaign = async (id) => {
    try {
      await axios.delete(`/api/push/campaigns/${id}`);
      fetchCampaigns();
    } catch (error) {
      console.error('Error deleting campaign:', error);
    }
  };

  const handleEditTime = (campaignId) => {
    const campaign = campaigns.find(c => c._id === campaignId);
    setEditingCampaign(campaign);
    const campaignTime = new Date(campaign.time);
    const formattedTime = `${String(campaignTime.getHours()).padStart(2, '0')}:${String(campaignTime.getMinutes()).padStart(2, '0')}`;
    setEditTime(formattedTime);
    setEditDialogOpen(true);
  };

  const handleSaveEditTime = async () => {
    if (validateTime(editTime)) {
      try {
        await axios.put(`/api/push/campaigns/${editingCampaign._id}/time`, {
          time: editTime
        });
        setEditDialogOpen(false);
        fetchCampaigns();
      } catch (error) {
        console.error('Error updating campaign time:', error);
      }
    } else {
      setTimeError('Пожалуйста, введите корректное время в формате ЧЧ:ММ');
    }
  };

  const formatCampaignFrequency = (campaign) => {
    switch (campaign.frequency) {
      case 'minutes':
        return `Каждые ${campaign.customMinutes} минут`;
      case 'daily':
        return 'Ежедневно';
      case 'weekly':
        return 'Еженедельно';
      case 'monthly':
        return 'Ежемесячно';
      default:
        return 'Неизвестно';
    }
  };

  const formatNextSendTime = (campaign) => {
    if (!campaign.isRegular) return 'Одноразовая кампания';

    const now = new Date();
    const campaignTime = new Date(campaign.time); // Предполагается, что campaign.time уже в UTC

    const formatDate = (date) => {
      return date.toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
    };

    switch (campaign.frequency) {
      case 'minutes':
        const nextTime = new Date(campaign.lastSent || campaign.createdAt);
        nextTime.setMinutes(nextTime.getMinutes() + campaign.customMinutes);
        return nextTime > now ? `Следующая отправка: ${formatDate(nextTime)}` : 'Ожидает отправки';
      case 'daily':
        let dailyTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), campaignTime.getUTCHours(), campaignTime.getUTCMinutes());
        if (dailyTime < now) dailyTime.setDate(dailyTime.getDate() + 1);
        return `Следующая отправка: ${formatDate(dailyTime)}`;
      case 'weekly':
        const today = now.getDay();
        const daysUntilNext = campaign.weekDays.findIndex((day, index) =>
          day && (index > today || (index === today && campaignTime > now))
        );
        let weeklyTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (daysUntilNext === -1 ? 7 : daysUntilNext) - today, campaignTime.getUTCHours(), campaignTime.getUTCMinutes());
        return `Следующая отправка: ${formatDate(weeklyTime)}`;
      case 'monthly':
        let monthlyTime = new Date(now.getFullYear(), now.getMonth(), campaign.monthDay, campaignTime.getUTCHours(), campaignTime.getUTCMinutes());
        if (monthlyTime < now) monthlyTime.setMonth(monthlyTime.getMonth() + 1);
        return `Следующая отправка: ${formatDate(monthlyTime)}`;
      default:
        return 'Неизвестно';
    }
  };

  const defaultChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Отправлено',
        data: [65, 59, 80, 81, 56, 55],
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      },
      {
        label: 'Открыто',
        data: [28, 48, 40, 19, 86, 27],
        borderColor: 'rgb(255, 99, 132)',
        tension: 0.1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: isRealData ? 'Статистика уведомлений за последние 30 дней' : 'Пример графика статистики уведомлений'
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Дата'
        },
        ticks: {
          maxTicksLimit: 10,
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Количество'
        },
        suggestedMin: 0,
      }
    },
  };

  return (
    <div className="max-w-screen-lg mx-auto">
      <h1 className="text-4xl font-bold mb-4">Push-уведомления</h1>

      <div className="bg-white shadow-md p-6 mb-6">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Выберите PWA</label>
              <select
                value={selectedPwa}
                onChange={(e) => setSelectedPwa(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              >
                {pwaApps.map((app) => (
                  <option key={app.pwaId} value={app.pwaId}>{app.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Заголовок</label>
              <input
                type="text"
                name="title"
                value={notificationData.title}
                onChange={handleInputChange}
                maxLength={50}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              <p className="text-sm text-gray-500">{titleChars}/50</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Текст</label>
              <textarea
                name="body"
                rows={3}
                value={notificationData.body}
                onChange={handleInputChange}
                maxLength={180}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              <p className="text-sm text-gray-500">{bodyChars}/180</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">URL иконки (необязательно)</label>
              <input
                type="text"
                name="icon"
                value={notificationData.icon}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={notificationData.useCustomUrl}
                onChange={handleSwitchChange}
                name="useCustomUrl"
                id="useCustomUrl"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="useCustomUrl" className="ml-2 block text-sm text-gray-700">
                Использовать пользовательский URL открытия
              </label>
            </div>
            {notificationData.useCustomUrl ? (
              <div>
                <label className="block text-sm font-medium text-gray-700">URL открытия</label>
                <input
                  type="text"
                  name="url"
                  value={notificationData.url}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            ) : (
              <p className="text-sm text-gray-500">
                По умолчанию будет открыто PWA приложение.
              </p>
            )}
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={notificationData.isRegular}
                onChange={handleSwitchChange}
                name="isRegular"
                id="isRegular"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="isRegular" className="ml-2 block text-sm text-gray-700">
                Регулярная отправка
              </label>
            </div>
            {notificationData.isRegular && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Частота</label>
                  <select
                    value={notificationData.frequency}
                    onChange={handleInputChange}
                    name="frequency"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    {/* <option value="minutes">Каждые X минут</option> */}
                    <option value="daily">Ежедневно</option>
                    <option value="weekly">Еженедельно</option>
                    <option value="monthly">Ежемесячно</option>
                  </select>
                </div>
                {notificationData.frequency === 'minutes' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Количество минут</label>
                    <input
                      type="number"
                      name="customMinutes"
                      value={notificationData.customMinutes}
                      onChange={handleInputChange}
                      min="1"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                )}
                {notificationData.frequency === 'weekly' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Дни недели</label>
                    <div className="flex space-x-4 mt-2">
                      {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map((day, index) => {
                        const adjustedIndex = (index + 1) % 7;
                        return (
                          <div key={day} className="flex items-center">
                            <input
                              type="checkbox"
                              checked={notificationData.weekDays[adjustedIndex]}
                              onChange={() => handleWeekDayChange(adjustedIndex)}
                              id={`weekday-${adjustedIndex}`}
                              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label htmlFor={`weekday-${adjustedIndex}`} className="ml-2 text-sm text-gray-700">
                              {day}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {notificationData.frequency === 'monthly' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">День месяца: {notificationData.monthDay}</label>
                    <input
                      type="range"
                      min="1"
                      max="31"
                      value={notificationData.monthDay}
                      onChange={handleMonthDayChange}
                      className="mt-2 w-full"
                    />
                  </div>
                )}
                {notificationData.frequency !== 'minutes' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Время отправки (ЧЧ:ММ, 24-часовой формат)
                    </label>
                    <input
                      type="text"
                      name="time"
                      value={notificationData.time instanceof Date
                        ? `${String(notificationData.time.getHours()).padStart(2, '0')}:${String(notificationData.time.getMinutes()).padStart(2, '0')}`
                        : ''
                      }
                      onChange={handleTimeChange}
                      maxLength={5}
                      pattern="[0-9:]*"
                      className={`mt-1 block w-full border ${timeError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500`}
                    />
                    {timeError ? (
                      <p className="text-sm text-red-500">{timeError}</p>
                    ) : (
                      <p className="text-sm text-gray-500">Введите время в формате ЧЧ:ММ (например, 14:30)</p>
                    )}
                  </div>
                )}
                <p className="text-sm text-gray-500">
                  Текущее время сервера (локальное время): {serverTime instanceof Date && !isNaN(serverTime.getTime())
                    ? formatLocalTime(serverTime)
                    : 'Загрузка...'}
                </p>
              </>
            )}
            <div>
              <button
                type="submit"
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${titleChars > 50 || bodyChars > 180 ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'}`}
                disabled={titleChars > 50 || bodyChars > 180}
              >
                <PaperAirplaneIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Отправить
              </button>
            </div>
          </div>
        </form>
      </div>

      <h2 className="text-2xl font-bold mb-4">Активные кампании</h2>
      {paginatedCampaigns.map((campaign) => (
        <div key={campaign._id} className="border rounded-md mb-4">
          <button
            onClick={() => setOpenAccordion(campaign._id === openAccordion ? null : campaign._id)}
            className="w-full text-left px-4 py-2 bg-gray-100 flex justify-between items-center"
          >
            <span>{campaign.title}</span>
            <div className="flex items-center">
              <span className="ml-2 text-sm">{campaign.status}</span>
              <ChevronDownIcon className={`ml-2 h-5 w-5 transform ${campaign._id === openAccordion ? 'rotate-180' : ''}`} />
            </div>
          </button>
          {campaign._id === openAccordion && (
            <div className="px-4 py-4">
              <p className="text-base mb-2">
                <strong>Заголовок:</strong> {campaign.title}
              </p>
              <p className="text-base mb-2">
                <strong>Текст:</strong> {campaign.body}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                <strong>Тип кампании:</strong> {campaign.isRegular ? formatCampaignFrequency(campaign) : 'Одноразовая'}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                {formatNextSendTime(campaign)}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                {`Отправлено: ${campaign.stats.sent}, Открыто: ${campaign.stats.opened}`}
              </p>
              {campaign.isRegular && (
                <button
                  className={`inline-flex items-center px-4 py-2 mr-2 text-sm font-medium rounded-md shadow-sm text-white ${campaign.status === 'stopped' ? 'bg-green-600 hover:bg-green-700' : 'bg-red-600 hover:bg-red-700'}`}
                  onClick={() => {
                    campaign.status === 'stopped' ? handleStartCampaign(campaign._id) : handleStopCampaign(campaign._id);
                  }}
                >
                  {campaign.status === 'stopped' ? (
                    <>
                      <PlayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      Запустить
                    </>
                  ) : (
                    <>
                      <StopIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      Остановить
                    </>
                  )}
                </button>
              )}
              <button
                className="inline-flex items-center px-4 py-2 mr-2 text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                onClick={() => handleEditTime(campaign._id)}
              >
                <PencilIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Изменить время
              </button>
              <button
                className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
                onClick={() => handleDeleteCampaign(campaign._id)}
              >
                Удалить
              </button>
            </div>
          )}
        </div>
      ))}

      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
          className={`px-4 py-2 mx-1 text-sm font-medium rounded-md shadow-sm ${page === 1 ? 'bg-gray-300' : 'bg-indigo-600 hover:bg-indigo-700 text-white'}`}
        >
          Предыдущая
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 mx-1 text-sm font-medium rounded-md shadow-sm ${page === index + 1 ? 'bg-indigo-700 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
          className={`px-4 py-2 mx-1 text-sm font-medium rounded-md shadow-sm ${page === totalPages ? 'bg-gray-300' : 'bg-indigo-600 hover:bg-indigo-700 text-white'}`}
        >
          Следующая
        </button>
      </div>

      {/* Edit Time Modal */}
      {editDialogOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* Modal panel */}
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Изменить время отправки
                  </h3>
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Новое время отправки (ЧЧ:ММ, 24-часовой формат)
                    </label>
                    <input
                      type="text"
                      value={editTime}
                      onChange={handleEditTimeChange}
                      maxLength={5}
                      pattern="[0-9:]*"
                      className={`mt-1 block w-full border ${timeError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500`}
                    />
                    {timeError ? (
                      <p className="text-sm text-red-500">{timeError}</p>
                    ) : (
                      <p className="text-sm text-gray-500">Введите время в формате ЧЧ:ММ (например, 14:30)</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleSaveEditTime}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Сохранить
                </button>
                <button
                  onClick={() => setEditDialogOpen(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Отмена
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-6">
        <h2 className="text-2xl font-bold mb-4">Статистика</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white shadow-md p-4 text-center">
            <h3 className="text-lg font-medium">Всего отправлено</h3>
            <p className="text-2xl font-bold">{stats.totalSent}</p>
          </div>
          <div className="bg-white shadow-md p-4 text-center">
            <h3 className="text-lg font-medium">Всего открыто</h3>
            <p className="text-2xl font-bold">{stats.totalOpened}</p>
          </div>
          <div className="bg-white shadow-md p-4 text-center">
            <h3 className="text-lg font-medium">Всего подписчиков</h3>
            <p className="text-2xl font-bold">{stats.totalSubscribers}</p>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Line data={chartData || defaultChartData} options={chartOptions} />
        {!isRealData && (
          <p className="text-sm text-gray-500 text-center mt-2">
            Пока нет данных для отображения. График будет обновлен, когда появится статистика отправленных уведомлений.
          </p>
        )}
      </div>
    </div>
  );
}
