// client/src/logger.js
export const logToServer = (message) => {
  //console.log(`[Server Log]: ${message}`);
  fetch('https://pwa-gen.com/log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message }),
  }).catch((error) => console.error('Error logging to server:', error));
};