// /root/pwa-inapp/client/src/components/Landing/AppContent.js
import React from 'react';
import { logToServer } from '../../logger';

const AppContent = ({ manifest, installButtonRef, comments }) => {
    logToServer(`Rendering AppContent with manifest: ${JSON.stringify(manifest)}`);
    logToServer(`installButtonRef in AppContent: ${installButtonRef.current}`);

  return (
    // Ваш HTML код
    <div id="root">
    <div class="main_mainCWiz__ZMXnX" data-cat="apps">
        <div class="main_mainWrapper__pIvrB">
            <div class="main_mainWrapperSub1__KSX1v">
                <div class="main_mainWrapperSub2__2fl4f">
                    <div>
                        <header class="headerMenu_S3WLMd__E4IgZ ">
                            <nav class="headerMenu_oPjgQb__i95hX">
                                <a href="#" class="headerMenu_f0UV3d__ZFvbs">
                                    <svg class="headerMenu_kOqhQd__BUeE+" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" d="M0,0h40v40H0V0z"></path>
                                        <g>
                                            <path d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z" fill="#EA4335"></path>
                                            <path d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z" fill="#FBBC04"></path>
                                            <path d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z" fill="#4285F4"></path>
                                            <path d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z" fill="#34A853"></path>
                                        </g>
                                    </svg>
                                    <span>google_logo Play</span>
                                </a>
                                <div>
                                    <div class="headerMenu_P8HBFd__KYVgY">
                                        <div>
                                            <div class="headerMenu_VfPpkd_AznF2e_ZMv3u__Rp3kE headerMenu_ucf8re__dCVVU">
                                                <div class="headerMenu_VfPpkd_AznF2e_vzJc7b__yyxx+">
                                                <div className="headerMenu_VfPpkd_AznF2e_LUERP_vJ7A6b__mP-4Y" style={{ marginBottom: '0px' }}>
                                                        <span class="headerMenu_VfPpkd_AznF2e_LUERP_bN97Pc__wEJ7e">
                                                            <button class="headerMenu_VfPpkd_AznF2e__741bw headerMenu_WbUJNb__RwXeX headerMenu_cvzg3c__t08ro ">
                                                                <span class="headerMenu_VfPpkd_N5Lhkf__dKTlX">
                                                                    <span class="headerMenu_VfPpkd_jY41G_V67aGc__yrA69">Games</span>
                                                                    <span class="headerMenu_rvBHac__nGvxl headerMenu_VfPpkd_AznF2e_uDEFge__IOS+q">
                                                                        <span class="headerMenu_pawmVc__IzRlO"></span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <button class="headerMenu_VfPpkd_AznF2e__741bw headerMenu_WbUJNb__RwXeX headerMenu_q2wkXd__o+j0v headerMenu_catSelect__W8UR+">
                                                                <span class="headerMenu_VfPpkd_N5Lhkf__dKTlX">
                                                                    <span class="headerMenu_VfPpkd_jY41G_V67aGc__yrA69">Apps</span>
                                                                    <span class="headerMenu_rvBHac__nGvxl headerMenu_VfPpkd_AznF2e_uDEFge__IOS+q">
                                                                        <span class="headerMenu_pawmVc__IzRlO"></span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <button class="headerMenu_VfPpkd_AznF2e__741bw headerMenu_WbUJNb__RwXeX headerMenu_ROhV3c__WUtG-">
                                                                <span class="headerMenu_VfPpkd_N5Lhkf__dKTlX">
                                                                    <span class="headerMenu_VfPpkd_jY41G_V67aGc__yrA69">Movies</span>
                                                                    <span class="headerMenu_rvBHac__nGvxl">
                                                                        <span class="headerMenu_pawmVc__IzRlO"></span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <button class="headerMenu_VfPpkd_AznF2e__741bw headerMenu_WbUJNb__RwXeX headerMenu_AthEce__V76a7">
                                                                <span class="headerMenu_VfPpkd_N5Lhkf__dKTlX">
                                                                    <span class="headerMenu_VfPpkd_jY41G_V67aGc__yrA69">Books</span>
                                                                    <span class="headerMenu_rvBHac__nGvxl">
                                                                        <span class="headerMenu_pawmVc__IzRlO"></span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                            <button class="headerMenu_VfPpkd_AznF2e__741bw headerMenu_WbUJNb__RwXeX headerMenu_dUghbc__VUy-U">
                                                                <span class="headerMenu_VfPpkd_N5Lhkf__dKTlX">
                                                                    <span class="headerMenu_VfPpkd_jY41G_V67aGc__yrA69">Kids</span>
                                                                    <span class="headerMenu_rvBHac__nGvxl">
                                                                        <span class="headerMenu_pawmVc__IzRlO"></span>
                                                                    </span>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="headerMenu_oc9n0c__a3uH6">
                                    <div>
                                        <button class="headerMenu_VfPpkd_Bz112c_LgbsSe__JcpOf headerMenu_yHy1rc__+DE5p headerMenu_mN1ivc__0i57N">
                                            <div class="headerMenu_VfPpkd_Bz112c_Jh9lGc__Zi8o7"></div>
                                            <i class="headerMenu_google_material_icons__aka5y headerMenu_r9optf__R56Lc">search</i>
                                        </button>
                                    </div>
                                    <div>
                                        <button class="headerMenu_VfPpkd_Bz112c_LgbsSe__JcpOf headerMenu_yHy1rc__+DE5p headerMenu_mN1ivc__0i57N">
                                            <div class="headerMenu_VfPpkd_Bz112c_Jh9lGc__Zi8o7"></div>
                                            <i class="headerMenu_google_material_icons__aka5y headerMenu_r9optf__R56Lc">help_outline</i>
                                        </button>
                                    </div>
                                    <div>
                                        <div>
                                            <div class="headerMenu_VfPpkd_xl07Ob_XxIAqe_OWXEXe_oYxtQd__tN6Co">
                                                <div>
                                                    <button class="headerMenu_VfPpkd_Bz112c_LgbsSe__JcpOf headerMenu_yHy1rc__+DE5p headerMenu_mN1ivc__0i57N
                                headerMenu_PeWVCc__tHphA">
                                                        <div class="headerMenu_VfPpkd_Bz112c_Jh9lGc__Zi8o7"></div>
                                                        <img src="./images/logo_avatar_anonymous_color.png" class="headerMenu_WrEZCd__7s8kh"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                          </header>
                    </div>
                    <div class="header_wrapMT__s1Kgr">
                        <div class="header_header__6NUB2">
                            <div class="header_content__Y5WcG">
                                <div class="cWin">
                                    <div class="header_topContent__fuFtX">
                                        <div class="header_iconNameContent__uY1ks">
                                            <picture>
                                                <img class="header_iconImage__kRTYQ" src="images/logo.png"/>
                                            </picture>
                                            <div>
                                                <h1 class="header_name__wDmB9">
                                                    <span>{manifest?.name}</span>
                                                </h1>
                                                <div class="header_devInfo__bqnmB">
                                                    <div class="header_devName__6PmbF">
                                                        <a>
                                                            <span>{manifest?.category}</span>
                                                        </a>
                                                    </div>
                                                    <div class="header_infoAds__yGLRx">
                                                        <div>
                                                            <span>Contains ads · In-app purchases</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="header_infoListContent__UlLT8">
                                            <div class="header_infoListContentList__xkMAE">
                                                <div class="header_infoListContentListWBlock__BFDyj">
                                                    <div class="header_infoListContentListBlock__D2C20">
                                                        <div class="header_infoListContentListBlockT__-Anx2">
                                                        {manifest.rating}<i class="google-material-icons" aria-hidden="true">star</i>
                                                        </div>
                                                        <div class="header_infoListContentListBlockB__5kNfV">{manifest.reviews} reviews</div>
                                                    </div>
                                                    <div class="header_infoListContentListBlock__D2C20">
                                                        <div class="header_infoListContentListBlockT__-Anx2">{manifest.installs}</div>
                                                        <div class="header_infoListContentListBlockB__5kNfV">Downloads</div>
                                                    </div>
                                                    <div class="header_infoListContentListBlock__D2C20">
                                                        <div class="header_infoListContentListBlockT__-Anx2">
                                                            <span class="header_infoListContentListBlockTIconCenter__jQQWg" aria-hidden="true">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                                    <rect fill="none" width="20" height="20"></rect>
                                                                    <path d="M10.54,11.09L8.66,9.22l-1.02,1.02l2.9,2.9l5.8-5.8l-1.02-1.01L10.54,11.09z M15.8,16.24H8.2L4.41,9.66L8.2,3h7.6l3.79,6.66 L15.8,16.24z M17,1H7L2,9.66l5,8.64V23l5-2l5,2v-4.69l5-8.64L17,1z"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <div class="header_infoListContentListBlockB__5kNfV">Editors' Choice</div>
                                                    </div>
                                                    <div class="header_infoListContentListBlock__D2C20">
                                                        <div class="header_infoListContentListBlockT__-Anx2">
                                                            <img class="header_infoListContentListBlockTIconAge__ERuTn" src="./images/18.png" />
                                                        </div>
                                                        <div class="header_infoListContentListBlockB__5kNfV">
                                                            <span>
                                                                <span>Rated for 18+</span>
                                                            </span>
                                                            <div class="header_infoListContentListBlockBB__980US">
                                                                <i class="google-material-icons" aria-hidden="true">info</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="header_btnContent__rH7Fv">
                                    <div class="header_btnContentInstall__UtT7y">
                                        <div class="header_btnContentInstall_sub1__4I4Ui">
                                            <div class="header_btnContentInstall_sub1cWiz__bCzJG">
                                                <div class="header_btnIns_W1__MhKZ7">
                                                    <div>
                                                        <div>
                                                            <div class="header_btnIns_W4__mjnfw">
                                                                <button class="header_btnIns_Btn__uB2nz" ref={installButtonRef}>
                                                                    <div class="header_btnIns_BtnProg__SMou9">
                                                                        <div class="header_btnIns_BtnProgLine__m+nW7"></div>
                                                                    </div>
                                                                    <div class="header_btnIns_BtnTitle__ctqbc install-button">Install</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="header_btnContentAddFav__-vdWi">
                                        <div>
                                            <div>
                                                <div>
                                                    <div>
                                                        <div class="header_btnContentAddFavPB__+xxML">
                                                            <button class="header_btnContentAddFavBtn__-l9-l">
                                                                <span class="header_btnContentAddFavBtn_icon__80TSv" aria-hidden="true">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24">
                                                                        <path d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"></path>
                                                                    </svg>
                                                                </span>
                                                                <span class="header_btnContentAddFavBtn_txt__3-jmH" aria-hidden="true">Add to wishlist</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="header_successInstallContainer__XLO2v">
                                    <div class="header_successInstallContainer_sub1__kTxDU">
                                        <i class="google-material-icons" aria-hidden="true">devices</i>
                                        <span>This app is available for all of your devices.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content_wrapper__45t+V">
                        <div class="content_wrapperContent__CC1qD">
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <div class="content_screens_wrapper__YeeJ3">
                                                <div class="content_screens_wList__fbowt">
                                                    <div class="content_screens_listItem__TasxL">
                                                        <div class="content_screens_wImg__vJV4o">
                                                            <picture>
                                                                <img alt="screen" src="./images/1.png"/>
                                                            </picture>
                                                        </div>
                                                    </div>
                                                    <div class="content_screens_listItem__TasxL">
                                                        <div class="content_screens_wImg__vJV4o">
                                                            <picture>
                                                                <img alt="screen" src="./images/2.png"/>
                                                            </picture>
                                                        </div>
                                                    </div>
                                                    <div class="content_screens_listItem__TasxL">
                                                        <div class="content_screens_wImg__vJV4o">
                                                            <picture>
                                                                <img alt="screen" src="./images/3.png"/>
                                                            </picture>
                                                        </div>
                                                    </div>
                                                    <div class="content_screens_listItem__TasxL">
                                                        <div class="content_screens_wImg__vJV4o">
                                                            <picture>
                                                                <source data-savepage-srcset="/assets-ucp/Ekyy/s26b64b62e72ed374e28b80dcecb8edaa/_r0x408.png" srcset="" type="image/png"/>
                                                                <source data-savepage-srcset="/assets-ucp/Ekyy/s26b64b62e72ed374e28b80dcecb8edaa/_r0x408.png" srcset="" type="image/webp"/>
                                                                <img alt="screen" data-savepage-src="/assets-ucp/Ekyy/s26b64b62e72ed374e28b80dcecb8edaa/_r0x408.png" src="./images/4.png"/>
                                                            </picture>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <section class="DescSelector_sel__TiLso">
                                                    <header class="DescSelector_header__kzxq+">
                                                        <div class="DescSelector_hTop__iz3Wu">
                                                            <div class="DescSelector_hTopT__lQ46T">
                                                                <h2>About this game</h2>
                                                            </div>
                                                            <div>
                                                                <button class="DescSelector_hBtn__2tAOY">
                                                                    <i class="google-material-icons DescSelector_hBtnIcon__lZHve" aria-hidden="true">arrow_forward</i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </header>
                                                    <div class="DescSelector_content__vd-g4">
                                                    <div className="content_desc_desc__2iBCe" dangerouslySetInnerHTML={{ __html: manifest.description }}></div>
                                                        <div class="content_desc_lastUpdate__+V7ZD">
                                                            <div>
                                                                <div class="content_desc_lastUpdateT__HgO8Q">Updated on</div>
                                                                <div class="content_desc_lastUpdateD__5pu-m">{new Date(manifest.updatedOn).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                                                            </div>
                                                        </div>
                                                        <div class="content_desc_tags__94Tv6"></div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <section class="DescSelector_sel__TiLso">
                                                    <header class="DescSelector_header__kzxq+">
                                                        <div class="DescSelector_hTop__iz3Wu">
                                                            <div class="DescSelector_hTopT__lQ46T">
                                                                <h2>Data safety</h2>
                                                            </div>
                                                            <div>
                                                                <button class="DescSelector_hBtn__2tAOY">
                                                                    <i class="google-material-icons DescSelector_hBtnIcon__lZHve" aria-hidden="true">arrow_forward</i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </header>
                                                    <div class="DescSelector_content__vd-g4">
                                                        In this section, developers can specify how applications collect and use data.
                                                        <div class="content_security_bWrapper__RbzB2">
                                                            <div class="content_security_secItem__J-ttM">
                                                                <div>
                                                                    No information.<div class="content_security_secItemM__BLD9K"></div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div class="content_security_moreW__ekeBw">
                                                                    <div class="content_security_moreW2__lh3Lo">
                                                                        <span class="content_security_moreT__4emtL"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <section class="DescSelector_sel__TiLso">
                                                    <header class="DescSelector_header__kzxq+">
                                                        <div class="DescSelector_hTop__iz3Wu">
                                                            <div class="DescSelector_hTopT__lQ46T">
                                                                <h2>Ratings and reviews</h2>
                                                            </div>
                                                            <div>
                                                                <button class="DescSelector_hBtn__2tAOY">
                                                                    <i class="google-material-icons DescSelector_hBtnIcon__lZHve" aria-hidden="true">arrow_forward</i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </header>
                                                    <div class="DescSelector_content__vd-g4">
                                                        <div class="Content_comments_rating_wrapperRating__linmK">
                                                            <div class="Content_comments_rating_wrapperRatingS2__lVLCJ">
                                                                <div class="Content_comments_rating_wrapperRatingS3__u6Z0l">
                                                                    <div>
                                                                        <div class="Content_comments_rating_ratingTitle__Xp3IS">{manifest.rating}</div>
                                                                        <div class="StarRating_starList__vWMsK">
                                                                            <span class="StarRating_starItem__+EBtP">
                                                                                <span class="StarRating_starItemFill__+2irG">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                            <span class="StarRating_starItem__+EBtP">
                                                                                <span class="StarRating_starItemFill__+2irG">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                            <span class="StarRating_starItem__+EBtP">
                                                                                <span class="StarRating_starItemFill__+2irG">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                            <span class="StarRating_starItem__+EBtP">
                                                                                <span class="StarRating_starItemFill__+2irG">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                            <span class="StarRating_starItem__+EBtP">
                                                                                <span>
                                                                                    <svg class="xdPrrd" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#c2906)">
                                                                                        <defs>
                                                                                            <linearGradient id="c2906">
                                                                                                <stop stop-opacity="1" offset="40%" class="StarRating_starItemFill__+2irG"></stop>
                                                                                                <stop stop-opacity="1" offset="60%" class="StarRating_starItemNotFill__ymUQS"></stop>
                                                                                            </linearGradient>
                                                                                        </defs>
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                        <div class="Content_comments_rating_review__Yc03t">{manifest.reviews} reviews</div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="Content_comments_rating_lineW__OCAF5">
                                                                            <div class="Content_comments_rating_lineNum__EGTtX">5</div>
                                                                            <div class="Content_comments_rating_lineBase__ohC62">
                                                                            <div className="Content_comments_rating_lineBaseFill__PFHuw" style={{ width: '84%' }}></div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="Content_comments_rating_lineW__OCAF5">
                                                                            <div class="Content_comments_rating_lineNum__EGTtX">4</div>
                                                                            <div class="Content_comments_rating_lineBase__ohC62">
                                                                            <div className="Content_comments_rating_lineBaseFill__PFHuw" style={{ width: '12%' }}></div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="Content_comments_rating_lineW__OCAF5">
                                                                            <div class="Content_comments_rating_lineNum__EGTtX">3</div>
                                                                            <div class="Content_comments_rating_lineBase__ohC62">
                                                                            <div className="Content_comments_rating_lineBaseFill__PFHuw" style={{ width: '4%' }}></div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="Content_comments_rating_lineW__OCAF5">
                                                                            <div class="Content_comments_rating_lineNum__EGTtX">2</div>
                                                                            <div class="Content_comments_rating_lineBase__ohC62">
                                                                            <div className="Content_comments_rating_lineBaseFill__PFHuw" style={{ width: '0%' }}></div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="Content_comments_rating_lineW__OCAF5">
                                                                            <div class="Content_comments_rating_lineNum__EGTtX">1</div>
                                                                            <div class="Content_comments_rating_lineBase__ohC62">
                                                                            <div className="Content_comments_rating_lineBaseFill__PFHuw" style={{ width: '0%' }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="content_comments_commW__KS6qP">
                                                            <header>
                                                                <div class="content_comments_cHTom__-3xre">
                                                                    <div class="content_comments_cHTomLeft__Uz2TX">
                                                                        <picture>
                                                                            <img class="content_comments_commAuthImg__VOqdH" src="./images/avaComments.webp" alt="auth"/>
                                                                        </picture>
                                                                        <div class="content_comments_commAuth__x9rNc">Tarek</div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="content_comments_cHTomRight__35SLt">
                                                                            <div>
                                                                                <button class="content_comments_commBtn__eKEkm">
                                                                                    <i class="google-material-icons">more_vert</i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content_comments_ratingW__Icdsj">
                                                                    <div class="StarRating_starList__vWMsK">
                                                                    <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span>
                                                                                <svg class="xdPrrd" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#c2906)">
                                                                                    <defs>
                                                                                        <linearGradient id="c2906">
                                                                                            <stop stop-opacity="1" offset="78%" class="StarRating_starItemFill__+2irG"></stop>
                                                                                            <stop stop-opacity="1" offset="22%" class="StarRating_starItemNotFill__ymUQS"></stop>
                                                                                        </linearGradient>
                                                                                    </defs>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <span class="content_comments_commDate__6Wi+t">May 1, 2024</span>
                                                                </div>
                                                            </header>
                                                            <div class="content_comments_commBW__Tqqwl">
                                                            {comments[0]?.text || 'No comment'}<br/>
                                                            </div>
                                                            <div>
                                                                <div class="content_comments_likeB__A+YQO">0 people found this review helpful</div>
                                                                <footer class="content_comments_likeF__27Irc">
                                                                    <div class="content_comments_likeFInfo__zWZUk">Did you find this helpful?</div>
                                                                    <div>
                                                                        <div class="content_comments_likeFBtnWrap__yjgrs">
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>Yes</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>No</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </footer>
                                                            </div>
                                                        </div>
                                                        <div class="content_comments_commW__KS6qP">
                                                            <header>
                                                                <div class="content_comments_cHTom__-3xre">
                                                                    <div class="content_comments_cHTomLeft__Uz2TX">
                                                                        <picture>
                                                                            <img class="content_comments_commAuthImg__VOqdH" src="./images/avaComments.webp" alt="auth"/>
                                                                        </picture>
                                                                        <div class="content_comments_commAuth__x9rNc">Josef</div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="content_comments_cHTomRight__35SLt">
                                                                            <div>
                                                                                <button class="content_comments_commBtn__eKEkm">
                                                                                    <i class="google-material-icons">more_vert</i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content_comments_ratingW__Icdsj">
                                                                    <div class="StarRating_starList__vWMsK">
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <span class="content_comments_commDate__6Wi+t">April 22, 2024</span>
                                                                </div>
                                                            </header>
                                                            <div class="content_comments_commBW__Tqqwl">
                                                            {comments[1]?.text || 'No comment'}<br/>
                                                            </div>
                                                            <div>
                                                                <div class="content_comments_likeB__A+YQO">259 people found this review helpful</div>
                                                                <footer class="content_comments_likeF__27Irc">
                                                                    <div class="content_comments_likeFInfo__zWZUk">Did you find this helpful?</div>
                                                                    <div>
                                                                        <div class="content_comments_likeFBtnWrap__yjgrs">
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>Yes</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>No</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </footer>
                                                            </div>
                                                        </div>
                                                        <div class="content_comments_commW__KS6qP">
                                                            <header>
                                                                <div class="content_comments_cHTom__-3xre">
                                                                    <div class="content_comments_cHTomLeft__Uz2TX">
                                                                        <picture>
                                                                            <img class="content_comments_commAuthImg__VOqdH" src="./images/avaComments.webp" alt="auth"/>
                                                                        </picture>
                                                                        <div class="content_comments_commAuth__x9rNc">Yousef</div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="content_comments_cHTomRight__35SLt">
                                                                            <div>
                                                                                <button class="content_comments_commBtn__eKEkm">
                                                                                    <i class="google-material-icons">more_vert</i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content_comments_ratingW__Icdsj">
                                                                    <div class="StarRating_starList__vWMsK">
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <span class="content_comments_commDate__6Wi+t">April 22, 2024</span>
                                                                </div>
                                                            </header>
                                                            <div class="content_comments_commBW__Tqqwl">
                                                            {comments[2]?.text || 'No comment'}<br/>
                                                            </div>
                                                            <div>
                                                                <div class="content_comments_likeB__A+YQO">76 people found this review helpful</div>
                                                                <footer class="content_comments_likeF__27Irc">
                                                                    <div class="content_comments_likeFInfo__zWZUk">Did you find this helpful?</div>
                                                                    <div>
                                                                        <div class="content_comments_likeFBtnWrap__yjgrs">
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>Yes</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>No</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </footer>
                                                            </div>
                                                        </div>
                                                        <div class="content_comments_commW__KS6qP">
                                                            <header>
                                                                <div class="content_comments_cHTom__-3xre">
                                                                    <div class="content_comments_cHTomLeft__Uz2TX">
                                                                        <picture>
                                                                            <img class="content_comments_commAuthImg__VOqdH" src="./images/avaComments.webp" alt="auth"/>
                                                                        </picture>
                                                                        <div class="content_comments_commAuth__x9rNc">Karim</div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="content_comments_cHTomRight__35SLt">
                                                                            <div>
                                                                                <button class="content_comments_commBtn__eKEkm">
                                                                                    <i class="google-material-icons">more_vert</i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content_comments_ratingW__Icdsj">
                                                                    <div class="StarRating_starList__vWMsK">
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <span class="content_comments_commDate__6Wi+t">April 22, 2024</span>
                                                                </div>
                                                            </header>
                                                            <div class="content_comments_commBW__Tqqwl">
                                                            {comments[3]?.text || 'No comment'}<br/>
                                                            </div>
                                                            <div>
                                                                <div class="content_comments_likeB__A+YQO">130 people found this review helpful</div>
                                                                <footer class="content_comments_likeF__27Irc">
                                                                    <div class="content_comments_likeFInfo__zWZUk">Did you find this helpful?</div>
                                                                    <div>
                                                                        <div class="content_comments_likeFBtnWrap__yjgrs">
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>Yes</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>No</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </footer>
                                                            </div>
                                                        </div>
                                                        <div class="content_comments_commW__KS6qP">
                                                            <header>
                                                                <div class="content_comments_cHTom__-3xre">
                                                                    <div class="content_comments_cHTomLeft__Uz2TX">
                                                                        <picture>
                                                                            <img class="content_comments_commAuthImg__VOqdH" src="./images/avaComments.webp" alt="auth"/>
                                                                        </picture>
                                                                        <div class="content_comments_commAuth__x9rNc">Rami</div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="content_comments_cHTomRight__35SLt">
                                                                            <div>
                                                                                <button class="content_comments_commBtn__eKEkm">
                                                                                    <i class="google-material-icons">more_vert</i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="content_comments_ratingW__Icdsj">
                                                                    <div class="StarRating_starList__vWMsK">
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span className="StarRating_starItem__+EBtP" style={{ width: '12px', height: '12px' }}>
                                                                            <span class="StarRating_starItemFill__+2irG">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                    <span class="content_comments_commDate__6Wi+t">April 22, 2024</span>
                                                                </div>
                                                            </header>
                                                            <div class="content_comments_commBW__Tqqwl">
                                                            {comments[4]?.text || 'No comment'}<br/>
                                                            </div>
                                                            <div>
                                                                <div class="content_comments_likeB__A+YQO">243 people found this review helpful</div>
                                                                <footer class="content_comments_likeF__27Irc">
                                                                    <div class="content_comments_likeFInfo__zWZUk">Did you find this helpful?</div>
                                                                    <div>
                                                                        <div class="content_comments_likeFBtnWrap__yjgrs">
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>Yes</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="content_comments_likeFBtn__9gQqp">
                                                                                <div aria-hidden="true">
                                                                                    <span>No</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </footer>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <div class="content_comments_commAWD3__DTQm-">
                                                                    <button>
                                                                        <span>See all reviews</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <section class="DescSelector_sel__TiLso">
                                                    <header class="DescSelector_header__kzxq+">
                                                        <div class="DescSelector_hTop__iz3Wu">
                                                            <div class="DescSelector_hTopT__lQ46T">
                                                                <h2>What's new</h2>
                                                            </div>
                                                        </div>
                                                    </header>
                                                    <div class="DescSelector_content__vd-g4">
                                                        <div></div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <section class="DescSelector_sel__TiLso">
                                                    <header class="DescSelector_header__kzxq+">
                                                        <div class="DescSelector_hTop__iz3Wu">
                                                            <div class="DescSelector_hTopT__lQ46T">
                                                                <h2>Developer contact</h2>
                                                            </div>
                                                            <div>
                                                                <button class="DescSelector_hBtn__2tAOY">
                                                                    <i class="google-material-icons DescSelector_hBtnIcon__lZHve" aria-hidden="true">expand_more</i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </header>
                                                    <div class="DescSelector_content__vd-g4">
                                                    <div className="content_contact_wrapper__+9fi3" style={{ display: 'none' }}></div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div class="footer_wrapper__3VsCj">
                        <div class="footer_line__RKj3e"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
}

export default AppContent;
