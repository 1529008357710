// /root/pwa-inapp/client/src/pages/User/Statistics.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip as ChartTooltip,
  Legend as ChartLegend
} from 'chart.js';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  ChartTooltip,
  ChartLegend
);

function Statistics() {
  const [overallStats, setOverallStats] = useState(null);
  const [pwaStats, setPwaStats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const overallResponse = await axios.get('/api/statistics/overall');
        setOverallStats(overallResponse.data);

        const pwaResponse = await axios.get('/api/push/pwa-apps');
        const pwaData = await Promise.all(
          pwaResponse.data.map(async (pwa) => {
            const statsResponse = await axios.get(`/api/statistics/pwa/${pwa.pwaId}`);
            return { ...pwa, ...statsResponse.data };
          })
        );
        setPwaStats(pwaData);

        setLoading(false);
      } catch (error) {
        console.error('Ошибка при загрузке статистики:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <svg className="animate-spin h-10 w-10 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
        </svg>
      </div>
    );
  }

  return (
    <div className="max-w-screen-lg mx-auto px-4">
      <h1 className="text-4xl font-bold mb-6">Статистика</h1>

      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
        <div className="bg-white shadow-md p-4 text-center">
          <h2 className="text-lg font-medium">PWA приложения</h2>
          <p className="text-2xl font-bold">{overallStats?.pwaCount}</p>
        </div>
        <div className="bg-white shadow-md p-4 text-center">
          <h2 className="text-lg font-medium">Всего установок</h2>
          <p className="text-2xl font-bold">{overallStats?.totalInstalls}</p>
        </div>
        <div className="bg-white shadow-md p-4 text-center">
          <h2 className="text-lg font-medium">Отправлено уведомлений</h2>
          <p className="text-2xl font-bold">{overallStats?.totalPushSent}</p>
        </div>
        <div className="bg-white shadow-md p-4 text-center">
          <h2 className="text-lg font-medium">Открыто уведомлений</h2>
          <p className="text-2xl font-bold">{overallStats?.totalPushOpened}</p>
        </div>
        <div className="bg-white shadow-md p-4 text-center">
          <h2 className="text-lg font-medium">Всего постбэков</h2>
          <p className="text-2xl font-bold">{overallStats?.totalPostbacks}</p>
        </div>
      </div>

      <h2 className="text-2xl font-bold mt-8 mb-4">Статистика по PWA</h2>

      {pwaStats.map((pwa) => (
        <div key={pwa.pwaId} className="bg-white shadow-md p-6 mb-6">
          <h3 className="text-xl font-medium mb-4">{pwa.name}</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <p>Установки: {pwa.installCount}</p>
              <p>Подписчики: {pwa.subscriptionCount}</p>
              <p>Отправлено уведомлений: {pwa.pushSent}</p>
              <p>Открыто уведомлений: {pwa.pushOpened}</p>
              <p>Получено постбэков: {pwa.postbacksReceived}</p>
            </div>
            <div className="md:col-span-2">
              <div className="relative w-full h-64">
                <Bar
                  data={{
                    labels: ['Установки', 'Подписчики', 'Отправлено', 'Открыто', 'Постбэки'],
                    datasets: [{
                      label: 'Количество',
                      data: [pwa.installCount, pwa.subscriptionCount, pwa.pushSent, pwa.pushOpened, pwa.postbacksReceived],
                      backgroundColor: ['#4C51BF', '#ED64A6', '#48BB78', '#ECC94B', '#F56565'],
                    }]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <h4 className="text-lg font-medium mt-4 mb-2">Последние постбэки</h4>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Время</th>
                <th className="py-2 px-4 border-b">Событие</th>
                <th className="py-2 px-4 border-b">Сумма</th>
              </tr>
            </thead>
            <tbody>
              {pwa.recentPostbacks?.map((postback, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border-b">{new Date(postback.timestamp).toLocaleString()}</td>
                  <td className="py-2 px-4 border-b">{postback.event}</td>
                  <td className="py-2 px-4 border-b">{postback.amount || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

export default Statistics;