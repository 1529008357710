// /root/pwa-inapp/client/src/pages/GeneratePWA.js
import React, { useState, useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import dayjs from 'dayjs';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const categories = [
  { cat_key: 'ART_AND_DESIGN', name: 'Art & Design' },
  { cat_key: 'AUTO_AND_VEHICLES', name: 'Auto & Vehicles' },
  { cat_key: 'BEAUTY', name: 'Beauty' },
  { cat_key: 'BOOKS_AND_REFERENCE', name: 'Books & Reference' },
  { cat_key: 'BUSINESS', name: 'Business' },
  { cat_key: 'COMICS', name: 'Comics' },
  { cat_key: 'COMMUNICATION', name: 'Communication' },
  { cat_key: 'DATING', name: 'Dating' },
  { cat_key: 'EDUCATION', name: 'Education' },
  { cat_key: 'ENTERTAINMENT', name: 'Entertainment' },
  { cat_key: 'EVENTS', name: 'Events' },
  { cat_key: 'FINANCE', name: 'Finance' },
  { cat_key: 'FOOD_AND_DRINK', name: 'Food & Drink' },
  { cat_key: 'HEALTH_AND_FITNESS', name: 'Health & Fitness' },
  { cat_key: 'HOUSE_AND_HOME', name: 'House & Home' },
  { cat_key: 'LIFESTYLE', name: 'Lifestyle' },
  { cat_key: 'MAPS_AND_NAVIGATION', name: 'Maps & Navigation' },
  { cat_key: 'MEDICAL', name: 'Medical' },
  { cat_key: 'MUSIC_AND_AUDIO', name: 'Music & Audio' },
  { cat_key: 'NEWS_AND_MAGAZINES', name: 'News & Magazines' },
  { cat_key: 'PARENTING', name: 'Parenting' },
  { cat_key: 'PERSONALIZATION', name: 'Personalization' },
  { cat_key: 'PHOTOGRAPHY', name: 'Photography' },
  { cat_key: 'PRODUCTIVITY', name: 'Productivity' },
  { cat_key: 'SHOPPING', name: 'Shopping' },
  { cat_key: 'SOCIAL', name: 'Social' },
  { cat_key: 'SPORTS', name: 'Sports' },
  { cat_key: 'TOOLS', name: 'Tools' },
  { cat_key: 'TRAVEL_AND_LOCAL', name: 'Travel & Local' },
  { cat_key: 'VIDEO_PLAYERS', name: 'Video Players & Editors' },
  { cat_key: 'WEATHER', name: 'Weather' },
  { cat_key: 'LIBRARIES_AND_DEMO', name: 'Libraries & Demo' },
  { cat_key: 'GAME_ARCADE', name: 'Arcade' },
  { cat_key: 'GAME_PUZZLE', name: 'Puzzle' },
  { cat_key: 'GAME_CARD', name: 'Cards' },
  { cat_key: 'GAME_CASUAL', name: 'Casual' },
  { cat_key: 'GAME_RACING', name: 'Racing' },
  { cat_key: 'GAME_SPORTS', name: 'Sport Games' },
  { cat_key: 'GAME_ACTION', name: 'Action' },
  { cat_key: 'GAME_ADVENTURE', name: 'Adventure' },
  { cat_key: 'GAME_BOARD', name: 'Board' },
  { cat_key: 'GAME_CASINO', name: 'Casino' },
  { cat_key: 'GAME_EDUCATIONAL', name: 'Educational' },
  { cat_key: 'GAME_MUSIC', name: 'Music Games' },
  { cat_key: 'GAME_ROLE_PLAYING', name: 'Role Playing' },
  { cat_key: 'GAME_SIMULATION', name: 'Simulation' },
  { cat_key: 'GAME_STRATEGY', name: 'Strategy' },
  { cat_key: 'GAME_TRIVIA', name: 'Trivia' },
  { cat_key: 'GAME_WORD', name: 'Word Games' },
  { cat_key: 'FAMILY', name: 'Family All Ages' },
  { cat_key: 'FAMILY_ACTION', name: 'Family Action' },
  { cat_key: 'FAMILY_BRAINGAMES', name: 'Family Brain Games' },
  { cat_key: 'FAMILY_CREATE', name: 'Family Create' },
  { cat_key: 'FAMILY_EDUCATION', name: 'Family Education' },
  { cat_key: 'FAMILY_MUSICVIDEO', name: 'Family Music & Video' },
  { cat_key: 'FAMILY_PRETEND', name: 'Family Pretend Play' },
];

function GeneratePWA() {
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [icon, setIcon] = useState(null);
  const [category, setCategory] = useState('Casino');
  const [pwaUrl, setPwaUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rating, setRating] = useState(4.4);
  const [reviews, setReviews] = useState(669);
  const [installs, setInstalls] = useState('100K+');
  const [updatedOn, setUpdatedOn] = useState(dayjs());
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [images, setImages] = useState([]);
  const { user, checkApprovalStatus } = useAuth();
  const [isApproved, setIsApproved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [comments, setComments] = useState([]);
  const [useRandomComments, setUseRandomComments] = useState(true);
  const [selectedComments, setSelectedComments] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [userTariff, setUserTariff] = useState(null);
  const [tariffError, setTariffError] = useState(null);
  const [systemDomains, setSystemDomains] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    const pngFiles = acceptedFiles.filter(file => file.type === 'image/png');
    if (pngFiles.length === 4) {
      setImages(pngFiles);
    } else {
      alert(`Пожалуйста, загрузите ровно 4 PNG изображения. Вы загрузили ${pngFiles.length} допустимых PNG файла(ов).`);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png']
    },
    multiple: true,
    maxFiles: 4
  });

  useEffect(() => {
    const checkStatus = async () => {
      if (user) {
        const status = await checkApprovalStatus();
        setIsApproved(status);
      }
      setLoading(false);
    };
    checkStatus();

    const fetchDomains = async () => {
      try {
        const response = await axios.get('https://pwa-gen.com/api/domains');
        setDomains(response.data);
      } catch (error) {
        console.error('Ошибка при получении доменов:', error);
      }
    };
    fetchDomains();

    const fetchComments = async () => {
      try {
        const response = await axios.get('https://pwa-gen.com/api/comments');
        setComments(response.data.comments);
      } catch (error) {
        console.error('Ошибка при получении комментариев:', error);
      }
    };
    fetchComments();

    const fetchSystemDomains = async () => {
      try {
        const response = await axios.get('/api/system-domains');
        setSystemDomains(response.data);
      } catch (error) {
        console.error('Ошибка при получении системных доменов:', error);
      }
    };
    fetchSystemDomains();

  }, [user, checkApprovalStatus]);

  useEffect(() => {
    const fetchUserTariff = async () => {
      try {
        const response = await axios.get('/api/tariffs/user/tariff');
        if (response.data.error === 'no_tariff') {
          setTariffError(response.data.message);
        } else {
          setUserTariff(response.data);
        }
      } catch (error) {
        console.error('Ошибка при получении тарифа пользователя:', error);
        setTariffError('Не удалось получить информацию о тарифе');
      }
    };
    fetchUserTariff();
  }, []);

  useEffect(() => {
    setIsFormValid(
      name.trim() !== '' &&
      website.trim() !== '' &&
      icon !== null &&
      images.length === 4 &&
      selectedDomain !== '' &&
      description.getCurrentContent().hasText()
    );
  }, [name, website, icon, images, selectedDomain, description]);

  const handleCommentToggle = (comment) => () => {
    const currentIndex = selectedComments.findIndex(c => c._id === comment._id);
    const newSelectedComments = [...selectedComments];

    if (currentIndex === -1) {
      newSelectedComments.push(comment);
    } else {
      newSelectedComments.splice(currentIndex, 1);
    }

    setSelectedComments(newSelectedComments);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      alert('Пожалуйста, заполните все обязательные поля перед генерацией.');
      return;
    }
    setIsGenerating(true);
    setProgress(0);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('website', website);
    formData.append('icon', icon);
    images.forEach((image, index) => {
      formData.append(`image${index + 1}`, image);
    });
    formData.append('category', category);
    formData.append('rating', rating);
    formData.append('reviews', reviews);
    formData.append('installs', installs);
    formData.append('updatedOn', updatedOn.toISOString());
    formData.append('description', draftToHtml(convertToRaw(description.getCurrentContent())));
    formData.append('domain', selectedDomain);

    try {
      let commentsToUse = [];
      if (useRandomComments) {
        const randomCommentsResponse = await axios.get('https://pwa-gen.com/api/comments/random');
        commentsToUse = randomCommentsResponse.data;
      } else {
        commentsToUse = selectedComments;
      }

      formData.append('comments', JSON.stringify(commentsToUse));

      const response = await axios.post('https://pwa-gen.com/generate-pwa-go', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      });
      const { pwaUrl } = response.data;
      setPwaUrl(pwaUrl);
      setErrorMessage('');
    } catch (error) {
      console.error('Ошибка при генерации PWA:', error);
      setErrorMessage(error.response?.data?.error || 'Ошибка при генерации PWA');
      setPwaUrl('');
    } finally {
      setIsGenerating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!user || !isApproved) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Создать PWA</h1>
      
      {tariffError ? (
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
        <p className="font-bold">Внимание</p>
        <p>{tariffError}</p>
        <p className="mt-2">
          <a href="/tariffs" className="font-medium underline">
            Перейти к выбору тарифа
          </a>
        </p>
      </div>
    ) : userTariff && (
      <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-6" role="alert">
        <p className="font-bold">Текущий тариф: {userTariff.name}</p>
        <p>Осталось приложений: {userTariff.remainingApps} из {userTariff.pwaLimit}</p>
      </div>
    )}
{!tariffError && (
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Название */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">Название *</label>
          <input
            type="text"
            id="name"
            className="mt-1 block w-full border-2 border-solid border-gray-700 rounded-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
  
        {/* Сайт */}
        <div>
          <label htmlFor="website" className="block text-sm font-medium text-gray-700">Сайт (с https в ссылке) *</label>
          <input
            type="text"
            id="website"
            className="mt-1 block w-full border-2 border-solid border-gray-700 rounded-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            required
          />
        </div>
  
        {/* Категория */}
        <div>
          <label htmlFor="category" className="block text-sm font-medium text-gray-700">Категория</label>
          <select
            id="category"
            className="mt-1 block w-full border-2 border-solid border-gray-700 bg-white rounded-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((cat) => (
              <option key={cat.cat_key} value={cat.name}>{cat.name}</option>
            ))}
          </select>
        </div>
  
        {/* Рейтинг */}
        <div>
          <label htmlFor="rating" className="block text-sm font-medium text-gray-700">Рейтинг: {rating}</label>
          <input
            type="range"
            id="rating"
            className="w-full"
            min="0.1"
            max="5"
            step="0.1"
            value={rating}
            onChange={(e) => setRating(parseFloat(e.target.value))}
          />
        </div>
  
        {/* Отзывы */}
        <div>
          <label htmlFor="reviews" className="block text-sm font-medium text-gray-700">Отзывы: {reviews}</label>
          <input
            type="range"
            id="reviews"
            className="w-full"
            min="1"
            max="10000"
            value={reviews}
            onChange={(e) => setReviews(parseInt(e.target.value))}
          />
        </div>
  
        {/* Установки */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Установки</label>
          <div className="mt-2 space-y-2">
            {['1K+', '5K+', '10K+', '50K+', '100K+', '500K+'].map((value) => (
              <label key={value} className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  className="form-radio text-indigo-600"
                  value={value}
                  checked={installs === value}
                  onChange={(e) => setInstalls(e.target.value)}
                />
                <span className="ml-2">{value}</span>
              </label>
            ))}
          </div>
        </div>
  
        {/* Дата обновления */}
        <div>
          <label htmlFor="updatedOn" className="block text-sm font-medium text-gray-700">Дата обновления</label>
          <input
            type="date"
            id="updatedOn"
            className="mt-1 block w-full border-2 border-solid border-gray-700 rounded-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={updatedOn.format('YYYY-MM-DD')}
            onChange={(e) => setUpdatedOn(dayjs(e.target.value))}
          />
        </div>
  
        {/* Описание */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Описание *</label>
          <div className="mt-1 border-2 border-solid border-gray-700 rounded-md">
            <Editor
              editorState={description}
              onEditorStateChange={setDescription}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class px-3 py-2 min-h-[200px]"
              toolbarClassName="toolbar-class"
            />
          </div>
        </div>
  
        {/* Загрузка изображений */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Загрузить 4 PNG изображения *</label>
          <div {...getRootProps()} className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed border-gray-700 rounded-md">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                >
                  <span>Загрузить файлы</span>
                  <input {...getInputProps()} className="sr-only" />
                </label>
                <p className="pl-1">или перетащите их сюда</p>
              </div>
              <p className="text-xs text-gray-500">PNG до 10MB</p>
            </div>
          </div>
        </div>
        {images.length > 0 && (
          <p className="text-sm text-gray-500">
            Выбранные изображения: {images.map(file => file.name).join(', ')}
          </p>
        )}
  
        {/* Иконка */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Иконка *</label>
          <input
            type="file"
            accept="image/png"
            onChange={(e) => setIcon(e.target.files[0])}
            required
            className="mt-1 block w-full text-sm text-gray-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-md file:border-2 file:border-solid file:border-gray-700
              file:text-sm file:font-medium
              file:bg-white file:text-gray-700
              hover:file:bg-gray-100"
          />
        </div>
        {icon && <p className="mt-1 text-sm text-gray-500">Выбранная иконка: {icon.name}</p>}
  
        {/* Домен */}
        <div>
          <label htmlFor="domain" className="block text-sm font-medium text-gray-700">Домен *</label>
          <select
            id="domain"
            className="mt-1 block w-full border-2 border-solid border-gray-700 bg-white rounded-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
            required
          >
            <option value="" disabled>Выберите домен</option>
            <optgroup label="Системные домены">
              {systemDomains.map((domain) => (
                <option key={domain._id} value={`system:${domain.name}`}>{domain.name}</option>
              ))}
            </optgroup>
            <optgroup label="Ваши домены">
              {domains.map((domain) => (
                <option key={domain._id} value={domain.name}>{domain.name}</option>
              ))}
              {domains.flatMap((domain) =>
                domain.subdomains.map((subdomain) => (
                  <option key={`${domain._id}-${subdomain.name}`} value={`${subdomain.name}.${domain.name}`}>
                    {`${subdomain.name}.${domain.name}`}
                  </option>
                ))
              )}
            </optgroup>
          </select>
        </div>
  
        {/* Комментарии */}
        <div>
          <h2 className="text-lg font-medium text-gray-900">Комментарии</h2>
          {comments.length < 5 ? (
            <div className="mt-2 bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    Добавьте не менее 5 комментариев.
                    <a href="https://pwa-gen.com/comments" className="font-medium underline text-yellow-700 hover:text-yellow-600">
                      Перейти к добавлению комментариев
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <label className="inline-flex items-center mt-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-indigo-600"
                  checked={useRandomComments}
                  onChange={(e) => setUseRandomComments(e.target.checked)}
                />
                <span className="ml-2 text-gray-700">Использовать случайные комментарии</span>
              </label>
              {!useRandomComments && (
                <ul className="mt-3 divide-y divide-gray-200">
                  {comments.map((comment) => (
                    <li key={comment._id} className="py-4 flex">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        checked={selectedComments.findIndex(c => c._id === comment._id) !== -1}
                        onChange={handleCommentToggle(comment)}
                      />
                      <label className="ml-3 block text-sm font-medium text-gray-700">
                        {comment.text}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
  
        {/* Кнопка создания */}
        <div>
          <button
            type="submit"
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium text-white ${
              isFormValid ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-400 cursor-not-allowed'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            disabled={!isFormValid || isGenerating || (comments.length < 5)}
          >
            {isGenerating ? 'Генерация...' : 'Создать'}
          </button>
        </div>
      </form>
  )}
      {!isFormValid && ( 
        <p className="mt-2 text-sm text-red-600">
          Пожалуйста, заполните все обязательные поля, отмеченные звездочкой (*).
        </p>
      )}
  
      {/* Прогресс генерации */}
      {isGenerating && (
        <div className="mt-4">
          <div className="relative pt-1">
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
              <div
                style={{ width: `${progress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500 transition-all duration-300 ease-in-out"
              ></div>
            </div>
          </div>
          <p className="text-center text-sm text-gray-600">{`${Math.round(progress)}%`}</p>
        </div>
      )}
  
      {/* Успешное сообщение */}
      {pwaUrl && (
        <div className="mt-4 bg-green-50 border-l-4 border-green-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">PWA успешно создано!</h3>
              <div className="mt-2 text-sm text-green-700">
                <a href={pwaUrl} target="_blank" rel="noopener noreferrer" className="font-medium underline hover:text-green-600">
                  {pwaUrl}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
  
{/* Сообщение об ошибке */}
{errorMessage && (
        <div className="mt-4 bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.293-4.293a1 1 0 011.414 0L10 14.414l1.293-1.293a1 1 0 011.414 1.414L11.414 16l1.293 1.293a1 1 0 01-1.414 1.414L10 17.414l-1.293 1.293a1 1 0 01-1.414-1.414L8.586 16l-1.293-1.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Ошибка</h3>
              <div className="mt-2 text-sm text-red-700">
                {errorMessage}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GeneratePWA;