// /root/pwa-inapp/client/src/pages/User/Tariffs.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

function Tariffs() {
  const [tariffs, setTariffs] = useState([]);
  const { user, refreshUser } = useAuth();

  useEffect(() => {
    fetchTariffs();
  }, []);

  const fetchTariffs = async () => {
    try {
      const response = await axios.get('/api/tariffs');
      setTariffs(response.data);
    } catch (error) {
      console.error('Ошибка при получении тарифов:', error);
    }
  };

  const purchaseTariff = async (tariffId) => {
    try {
      await axios.post('/api/tariffs/purchase', { tariffId });
      await refreshUser();
      alert('Тариф успешно приобретен!');
    } catch (error) {
      console.error('Ошибка при покупке тарифа:', error);
      alert(error.response?.data?.error || 'Ошибка при покупке тарифа');
    }
  };

  return (
    <div className="container mx-auto px-4 mt-8">
      <h1 className="text-3xl font-bold text-center mb-8">Выберите тариф</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {tariffs.map((tariff) => (
          <div key={tariff._id} className="border p-6 rounded-lg shadow-lg flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-4">{tariff.name}</h2>
            <p className="text-4xl font-extrabold mb-4">${tariff.pricePerMonth}<span className="text-lg font-normal">/месяц</span></p>
            <ul className="text-center mb-6">
              <li className="mb-2">Количество PWA: {tariff.pwaLimit}</li>
              <li className="mb-2">Пуш-уведомления: до {tariff.pushLimitPerPWA.toLocaleString()} в месяц на одно PWA</li>
            </ul>
            <button
              onClick={() => purchaseTariff(tariff._id)}
              className="mt-auto px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Выбрать
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tariffs;
