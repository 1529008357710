// /root/pwa-inapp/client/src/components/Landing/Landing.js
// инфа:
// checkAndSubscribe(); - вызов пушей запрос на подписку
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './Landing.css';
import AppContent from './AppContent';
import { logToServer } from '../../logger';
import axios from 'axios';

const Loading = () => <div>Loading...</div>;

const useFetchManifest = (setIsLoading) => {
  const [manifest, setManifest] = useState(null);

  useEffect(() => {
    const fetchManifest = async () => {
      try {
        const response = await fetch(`manifest.json`);
        if (response.ok) {
          const manifestData = await response.json();
          setManifest(manifestData);
        } else {
          logToServer('Error fetching manifest:', response.statusText);
        }
      } catch (error) {
        logToServer('Error fetching manifest:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchManifest();
  }, [setIsLoading]);

  return manifest;
};

const useDisplayModeStatus = () => {
  const [isStandaloneOrFullscreen, setIsStandaloneOrFullscreen] = useState(false);

  useEffect(() => {
    const checkDisplayModeStatus = () => {
      const isStandaloneOrFullscreen = window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.navigator.standalone;
      setIsStandaloneOrFullscreen(isStandaloneOrFullscreen);
    };

    checkDisplayModeStatus();
    window.addEventListener('resize', checkDisplayModeStatus);

    return () => {
      window.removeEventListener('resize', checkDisplayModeStatus);
    };
  }, []);

  return isStandaloneOrFullscreen;
};

function Landing() {
  const { appId } = useParams();
  const installButtonRef = useRef(null);
  const deferredPromptRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isInstalling, setIsInstalling] = useState(false);
  const [progress, setProgress] = useState(0);
  const manifest = useFetchManifest(setIsLoading);
  const isStandaloneOrFullscreen = useDisplayModeStatus();
  const isProgressBarRunning = useRef(false);
  const [pushPermissionStatus, setPushPermissionStatus] = useState('default');
  const [pwaId, setPwaId] = useState(null);
  const [comments, setComments] = useState([]);

  const checkAndSubscribe = useCallback(async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();

      if (subscription) {
        try {
          await fetch('/api/push/check-subscription', {
            method: 'POST',
            body: JSON.stringify(subscription),
            headers: { 'Content-Type': 'application/json' }
          });
          logToServer('Существующая подписка валидна');
        } catch (error) {
          logToServer('Существующая подписка невалидна, создаем новую');
          await subscription.unsubscribe();
          await subscribeToPush();
        }
      } else {
        await subscribeToPush();
      }
    } catch (error) {
      logToServer(`Ошибка при проверке подписки: ${error.message}`);
    }
  }, []);

  const subscribeToPush = useCallback(async () => {
    logToServer('Начало процесса подписки на push-уведомления');
    try {
      if (!('PushManager' in window)) {
        logToServer('Push-уведомления не поддерживаются этим браузером');
        return;
      }

      let vapidPublicKey;
      try {
        const response = await axios.get('/api/push/vapid-public-key');
        if (!response.data || !response.data.publicKey) {
          throw new Error('Некорректный ответ сервера: отсутствует publicKey');
        }
        vapidPublicKey = response.data.publicKey;
      } catch (error) {
        logToServer('Ошибка при получении VAPID ключа:', error.message);
        return;
      }

      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        logToServer(`Разрешение на отправку уведомлений не получено. Статус: ${permission}`);
        return;
      }

      const registration = await navigator.serviceWorker.ready;
      let subscription;
      try {
        const existingSubscription = await registration.pushManager.getSubscription();
        if (existingSubscription) {
          subscription = existingSubscription;
          logToServer('Использование существующей подписки');
        } else {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidPublicKey
          });
          logToServer('Создана новая подписка');
        }
      } catch (error) {
        logToServer('Ошибка при подписке:', error.message);
        return;
      }

      try {
        const subscribeResponse = await axios.post('/api/push/subscribe', { subscription });
        logToServer('Ответ сервера после отправки подписки:', JSON.stringify(subscribeResponse.data, null, 2));
      } catch (error) {
        logToServer('Ошибка при отправке подписки на сервер:', error.message);
        return;
      }

      logToServer('Процесс подписки на push-уведомления успешно завершен');
    } catch (error) {
      logToServer('Необработанная ошибка при подписке на push-уведомления:', error.message);
      logToServer('Стек ошибки:', error.stack);
    }
  }, []);

  const startProgressBar = useCallback(() => {
    const installButton = installButtonRef.current;
    if (installButton) {
      isProgressBarRunning.current = true;
      installButton.classList.add('installing');
      installButton.disabled = true;
      let currentProgress = 0;

      const updateProgress = () => {
        const increment = parseFloat((Math.random() * (0.5 - 0.25) + 0.25).toFixed(2));
        currentProgress = parseFloat((currentProgress + increment).toFixed(2));
        setProgress(currentProgress);
        installButton.textContent = `${currentProgress.toFixed(2)} / 12.38 MB`;

        if (currentProgress < 12.38) {
          setTimeout(updateProgress, (40 * 1000) / (12.38 / increment));
        } else {
          setIsInstalling(false);
          installButton.classList.remove('installing');
          installButton.disabled = false;
          setButtonToOpen();
          checkAndSubscribe();
          isProgressBarRunning.current = false;
        }
      };

      updateProgress();
    }
  }, [setIsInstalling, checkAndSubscribe]);

  const installPWA = useCallback(async () => {
    if (deferredPromptRef.current) {
      setIsInstalling(true);
      deferredPromptRef.current.prompt();
      const choiceResult = await deferredPromptRef.current.userChoice;
      if (choiceResult.outcome === 'accepted') {
        startProgressBar();
        const deviceId = generateDeviceId();
        try {
          await axios.post('/api/pwa/install');
        } catch (error) {
          logToServer('Error recording installation:', error.response ? error.response.data : error.message);
        }
      } else {
        setIsInstalling(false);
        deferredPromptRef.current = null;
        setButtonToInstall();
      }
    } else {
      logToServer('deferredPromptRef.current is null');
    }
  }, [startProgressBar]);

  const generateDeviceId = () => {
    return `${navigator.userAgent}-${new Date().getTime()}`;
  };

  const openPWA = useCallback(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.active.postMessage({ type: 'open-pwa' });
      });
    }
  }, []);

  const openPWAWithIntent = useCallback((event) => {
    event.preventDefault();
    const url = new URL(window.location.href);
    const appURL = `intent://navigate?url=${url.hostname}${url.pathname}?${url.searchParams.toString()}#Intent;scheme=googlechrome;end`;
    window.open(appURL, "_blank");
  }, []);

  const setButtonToInstall = useCallback(() => {
    const installButton = installButtonRef.current;
    if (installButton) {
      installButton.textContent = 'Install';
      installButton.removeEventListener('click', openPWA);
      installButton.addEventListener('click', installPWA);
      installButton.style.display = 'block';
    }
  }, [installPWA, openPWA]);

  const setButtonToOpen = useCallback(() => {
    const installButton = installButtonRef.current;
    if (installButton) {
      installButton.textContent = 'Open';
      installButton.removeEventListener('click', installPWA);
      installButton.addEventListener('click', openPWAWithIntent);
      installButton.style.display = 'block';
    }
  }, [installPWA, openPWAWithIntent]);

  const updateButtonState = useCallback(() => {
    const installButton = installButtonRef.current;
    if (installButton) {
      if (isInstalling || isProgressBarRunning.current) {
        // Progress bar is running
      } else if (isStandaloneOrFullscreen) {
        setButtonToOpen();
      } else if (deferredPromptRef.current) {
        setButtonToInstall();
      } else {
        setButtonToOpen();
      }
    }
  }, [isInstalling, isStandaloneOrFullscreen, setButtonToInstall, setButtonToOpen]);


  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get('/api/comments/pwa');
        console.log('Fetched comments:', response.data);
        setComments(response.data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };
  
    fetchComments();
  }, []); 

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      deferredPromptRef.current = e;
      updateButtonState();
    };

    const appInstalledHandler = () => {
      deferredPromptRef.current = null;
      setIsInstalling(false);
      updateButtonState();
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    window.addEventListener('appinstalled', appInstalledHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
      window.removeEventListener('appinstalled', appInstalledHandler);
    };
  }, [updateButtonState]);

  useEffect(() => {
    if (!isLoading && manifest) {
      setTimeout(updateButtonState, 1000);
    }
  }, [manifest, isLoading, updateButtonState]);

  useEffect(() => {
    const fetchPwaId = async () => {
      try {
        const response = await axios.get('/api/pwa');
        setPwaId(response.data.pwaId);
      } catch (error) {
        logToServer('Error fetching PWA ID:', error);
      }
    };

    fetchPwaId();

    // if ('serviceWorker' in navigator && 'PushManager' in window) {
    //   checkAndSubscribe();
    // }
  }, [checkAndSubscribe]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <AppContent manifest={manifest} installButtonRef={installButtonRef} comments={comments} />
    </div>
  );
}

export default Landing;