// /root/pwa-inapp/client/src/components/SidebarMenu.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { 
  Square3Stack3DIcon, CommandLineIcon, GlobeAltIcon, 
  RocketLaunchIcon, ChartBarIcon, WalletIcon, ArrowLeftOnRectangleIcon, TagIcon,
  UsersIcon, UserIcon, ChatBubbleLeftRightIcon, ChevronRightIcon, ChevronLeftIcon, AdjustmentsHorizontalIcon 
} from '@heroicons/react/24/outline';

const userMenuItems = [
  { text: 'Панель управления', icon: Square3Stack3DIcon, path: '/dashboard' },
  { text: 'PWA Приложения', icon: CommandLineIcon, path: '/pwa-applications' },
  { text: 'Настройки постбэков', icon: AdjustmentsHorizontalIcon, path: '/postback-settings' },
  { text: 'Комментарии', icon: ChatBubbleLeftRightIcon, path: '/comments' },
  { text: 'Мои домены', icon: GlobeAltIcon, path: '/my-domains' },
  { text: 'Пуш-отправка', icon: RocketLaunchIcon, path: '/goals' },
  { text: 'Статистика', icon: ChartBarIcon, path: '/statistics' },
  { text: 'Пополнение', icon: WalletIcon, path: '/top-up' },
  { text: 'Тарифы', icon: AdjustmentsHorizontalIcon, path: '/tariffs' },
];

const adminMenuItems = [
  ...userMenuItems,
  { text: 'Управление пользователями', icon: UsersIcon, path: '/admin/users' },
  { text: 'Промокоды', icon: TagIcon, path: '/admin/promo-codes' },
  { text: 'Системные домены', icon: GlobeAltIcon, path: '/admin/system-domains' },
];

function SidebarMenu() {
  const location = useLocation();
  const { user, logout, isAdmin } = useAuth();
  const menuItems = isAdmin ? adminMenuItems : userMenuItems;

  const [isExpanded, setIsExpanded] = useState(() => {
    const savedState = localStorage.getItem('sidebarExpanded');
    if (savedState !== null) {
      return JSON.parse(savedState);
    }
    return window.innerWidth > 768;
  });

  const [userTariff, setUserTariff] = useState(null);

  useEffect(() => {
    localStorage.setItem('sidebarExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  useEffect(() => {
    const fetchUserTariff = async () => {
      try {
        const response = await axios.get('/api/tariffs/user/tariff');
        setUserTariff(response.data.name ? response.data : null);
      } catch (error) {
        console.error('Ошибка при получении тарифа пользователя:', error);
        setUserTariff(null);
      }
    };
    fetchUserTariff();
  }, []);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`h-full bg-gray-100 shadow-lg transition-all duration-300 ease-in-out ${isExpanded ? 'w-64' : 'w-16'} flex flex-col`}>
      <div className="relative h-24 flex items-center justify-center bg-blue-600">
        <div className="w-10 h-10 rounded-full bg-white text-blue-600 flex items-center justify-center text-xl font-bold">
          {user?.username.charAt(0).toUpperCase()}
        </div>
        {isExpanded && (
          <div className="ml-3">
            <span className="font-medium text-white">{user?.username}</span>
            <p className="text-sm text-gray-200">
              Баланс: ${user?.balance ? user.balance.toFixed(2) : '0.00'}
            </p>
            <p className="text-sm text-gray-200">
              Тариф: {userTariff ? userTariff.name : "Тариф не куплен"}
            </p>
          </div>
        )}

        <button
          onClick={toggleSidebar}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 w-6 h-12 bg-white shadow-md rounded-r-md hover:bg-gray-100 transition-colors duration-200 flex items-center justify-center"
        >
          {isExpanded ? <ChevronLeftIcon className="w-4 h-4" /> : <ChevronRightIcon className="w-4 h-4" />}
        </button>
      </div>
      
      <nav className="flex-grow overflow-y-auto pt-4">
        <ul>
          {menuItems.map((item) => {
            const Icon = item.icon;
            return (
              <li key={item.text}>
                <Link
                  to={item.path}
                  className={`flex items-center py-2 px-4 ${isExpanded ? 'justify-start' : 'justify-center'} ${
                    location.pathname === item.path
                      ? 'bg-blue-100 text-blue-600'
                      : 'text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  <Icon className="w-6 h-6 flex-shrink-0" />
                  {isExpanded && <span className="ml-4 text-sm">{item.text}</span>}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      
      <div className={`p-4 ${isExpanded ? 'space-y-2' : 'flex flex-col items-center space-y-4'}`}>
        {isExpanded ? (
          <>
            <Link
              to="/profile"
              className="flex items-center justify-center w-full px-3 py-1.5 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              <UserIcon className="w-4 h-4 mr-2" />
              Профиль
            </Link>
            <button
              onClick={logout}
              className="flex items-center justify-center w-auto px-4 py-1.5 text-xs font-medium text-red-600 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50"
            >
              <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-2" />
              Выйти
            </button>
          </>
        ) : (
          <>
            <Link to="/profile" className="p-2 text-gray-700 hover:bg-gray-200 rounded-full">
              <UserIcon className="w-6 h-6" />
            </Link>
            <button onClick={logout} className="p-2 text-red-600 hover:bg-red-100 rounded-full">
              <ArrowLeftOnRectangleIcon className="w-6 h-6" />
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default SidebarMenu;