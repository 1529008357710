///root/pwa-inapp/client/src/pages/User/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const { user } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('/api/user-dashboard');
        setDashboardData(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('Не удалось загрузить данные дашборда. Пожалуйста, попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (error) {
    return <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <strong className="font-bold">Ошибка!</strong>
      <span className="block sm:inline"> {error}</span>
    </div>;
  }

  if (!dashboardData) {
    return <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
      <p className="font-bold">Информация</p>
      <p className="text-sm">Нет данных для отображения</p>
    </div>;
  }

  const installationData = {
    labels: ['1 Month Ago', 'Now'],
    datasets: [
      {
        label: 'Установки',
        data: [0, dashboardData.recentInstalls || 0],
        borderColor: 'rgb(59, 130, 246)',
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        tension: 0.1
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'category'
      },
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Панель управления</h1>
      <h2 className="text-xl mb-6">Добро пожаловать, {user?.username || 'Пользователь'}!</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold mb-4">Общая статистика</h3>
          <div className="space-y-2">
            <p>Всего PWA приложений: <span className="font-bold">{dashboardData.totalApps || 0}</span></p>
            <p>Активных приложений: <span className="font-bold">{dashboardData.activeApps || 0}</span></p>
            <p>Всего установок: <span className="font-bold">{dashboardData.totalInstalls || 0}</span></p>
            <p>Push-подписчиков: <span className="font-bold">{dashboardData.totalSubscribers || 0}</span></p>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow-md p-6 md:col-span-2">
          <h3 className="text-lg font-semibold mb-4">Установки за последний месяц</h3>
          <Line data={installationData} options={options} />
        </div>
        
        <div className="bg-white rounded-lg shadow-md p-6 md:col-span-3">
          <h3 className="text-lg font-semibold mb-4">Топ PWA приложений</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {dashboardData.topApps && dashboardData.topApps.map((app, index) => (
              <div key={index} className="bg-gray-100 rounded-lg p-4">
                <p className="font-semibold">{index + 1}. {app.name}</p>
                <p>Установки: <span className="font-bold">{app.installs}</span></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;