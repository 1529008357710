// /root/pwa-inapp/client/src/components/ErrorBoundary.js
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.loggedErrors = new Set(); // Хранение уникальных ошибок
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorKey = error.toString();

    if (!this.loggedErrors.has(errorKey)) {
      this.loggedErrors.add(errorKey);
      console.error("Uncaught! error:", error, errorInfo);

      fetch('/log-error', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          error: error.toString(),
          errorInfo: errorInfo.componentStack
        })
      });
    } else {
      console.warn("Repeated error:", errorKey); // Логирование повторяющейся ошибки в консоль
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Что-то пошло не так.</h1>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
