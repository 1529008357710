// /root/pwa-inapp/client/src/pages/PWAApp.js
import React, { useEffect, useState, useCallback, memo, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import InAppBrowser from '../components/InAppBrowser/InAppBrowser';
import Landing from '../components/Landing/Landing';
import { logToServer } from '../logger';

//const InAppBrowser = lazy(() => import('../components/InAppBrowser/InAppBrowser'));
//const Landing = lazy(() => import('../components/Landing/Landing'));

// Вспомогательная функция для логирования с временными метками
const logWithTimestamp = (message) => {
  const timestamp = new Date().toISOString();
  logToServer(`[${timestamp}] ${message}`);
};

// Кастомный хук для проверки устройства и браузера
const useDeviceAndBrowserCheck = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSupportedBrowser, setIsSupportedBrowser] = useState(false);

  useEffect(() => {
    try {
      const userAgent = navigator.userAgent.toLowerCase();
      logWithTimestamp(`User agent: ${userAgent}`);

      const mobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      setIsMobile(mobile);
      logWithTimestamp(`isMobile: ${mobile}`);

      const isYandexBrowser = /yabrowser/i.test(userAgent);
      const isOpera = /opera|opr/i.test(userAgent);
      const isChrome = /chrome|crios/i.test(userAgent) && !isYandexBrowser && !isOpera;
      const isSafari = /safari/i.test(userAgent) && !isChrome && !isYandexBrowser;
      const supportedBrowser = isChrome || isSafari || isOpera;
      setIsSupportedBrowser(supportedBrowser);
      logWithTimestamp(`Supported browser: ${supportedBrowser}`);
    } catch (error) {
      logWithTimestamp(`Error in useDeviceAndBrowserCheck: ${error.message}`);
    }
  }, []);

  return { isMobile, isSupportedBrowser };
};

// Кастомный хук для проверки установки PWA
const usePWAInstallationCheck = () => {
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);

  useEffect(() => {
    try {
      const isStandaloneOrFullscreen = window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || navigator.standalone;
      setIsPWAInstalled(isStandaloneOrFullscreen);
      logWithTimestamp(`window.matchMedia('(display-mode: standalone)').matches: ${window.matchMedia('(display-mode: standalone)').matches}`);
      logWithTimestamp(`window.matchMedia('(display-mode: fullscreen)').matches: ${window.matchMedia('(display-mode: fullscreen)').matches}`);
      logWithTimestamp(`navigator.standalone: ${navigator.standalone}`);
      logWithTimestamp(`isPWAInstalled: ${isStandaloneOrFullscreen}`);
    } catch (error) {
      logWithTimestamp(`Error in usePWAInstallationCheck: ${error.message}`);
    }
  }, []);

  return isPWAInstalled;
};

// Вспомогательная функция для открытия приложения в Chrome
const openAppInChrome = (appId, isPWAInstalled) => {
  try {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = /android/i.test(userAgent);
    logWithTimestamp(`isAndroid: ${isAndroid}`);

    if (isAndroid && !isPWAInstalled) {
      const url = new URL(window.location.href);
      const appURL = `intent://navigate?url=${url.hostname}${url.pathname}?${url.searchParams.toString()}#Intent;scheme=googlechrome;end`;
      logWithTimestamp(`Redirecting to Chrome with URL: ${appURL}`);
      window.location = appURL;
    } else if (!isAndroid) {
      logWithTimestamp('Non-Android device, opening page in current browser');
      window.location.href = `/app/${appId}`;
    }
  } catch (error) {
    logWithTimestamp(`Error in openAppInChrome: ${error.message}`);
  }
};

const PWAApp = memo(() => {
  const { appId } = useParams();
  const { isMobile, isSupportedBrowser } = useDeviceAndBrowserCheck();
  const isPWAInstalled = usePWAInstallationCheck();

  useEffect(() => {
    logWithTimestamp('Start of useEffect');

    if (isMobile) {
      logWithTimestamp('Opened on a mobile device!!!');

      if (!isSupportedBrowser) {
        logWithTimestamp('Unsupported browser');
        openAppInChrome(appId, isPWAInstalled);
      } else if (isPWAInstalled) {
        logWithTimestamp('PWA installed and opened in supported browser');
      } else {
        logWithTimestamp('PWA not installed');
      }
    } else {
      logWithTimestamp('Device is not mobile, no action taken.');
    }

    logWithTimestamp('End of useEffect');
  }, [isMobile, isSupportedBrowser, isPWAInstalled, appId]);

  logWithTimestamp('Rendering PWAApp component!');

  return (
    <>
      {!isMobile ? (
        //<Landing appId={appId} />
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h1>Opening is possible only from a mobile phone</h1>
        </div>
      ) : (
        <div>
          {isPWAInstalled ? (
            <>
              {logWithTimestamp(`PWAApp: InAppBrowser component will be rendered with appId: ${appId}`)}
              <InAppBrowser appId={appId} />
            </>
          ) : (
            <Landing appId={appId} />
          )}
        </div>
      )}
    </>
  );
});

export default PWAApp;
