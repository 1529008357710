// /root/pwa-inapp/client/src/pages/User/Comments.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

function Comments() {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editingComment, setEditingComment] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const commentsPerPage = 20;

  useEffect(() => {
    fetchComments();
  }, [page]);

  const fetchComments = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/comments?page=${page}&limit=${commentsPerPage}`);
      setComments(response.data.comments || []);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setError('Не удалось загрузить комментарии. Пожалуйста, попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingComment) {
        await axios.put(`/api/comments/${editingComment._id}`, { text: newComment });
      } else {
        await axios.post('/api/comments', { text: newComment });
      }
      setNewComment('');
      setEditingComment(null);
      fetchComments();
    } catch (error) {
      console.error('Error saving comment:', error);
      setError('Не удалось сохранить комментарий. Пожалуйста, попробуйте позже.');
    }
  };

  const handleEdit = (comment) => {
    setEditingComment(comment);
    setNewComment(comment.text);
  };

  const handleCancelEdit = () => {
    setEditingComment(null);
    setNewComment('');
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/comments/${id}`);
      fetchComments();
    } catch (error) {
      console.error('Error deleting comment:', error);
      setError('Не удалось удалить комментарий. Пожалуйста, попробуйте позже.');
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Управление комментариями</h1>
      <form onSubmit={handleSubmit} className="mb-8 border-2 border-black bg-yellow-100 p-4 rounded">
  <textarea
    className="w-full p-2 border-2 border-black bg-white rounded mb-2 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
    placeholder="Новый комментарий"
    value={newComment}
    onChange={(e) => setNewComment(e.target.value)}
    rows="3"
  />
  <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200">
    {editingComment ? 'Обновить комментарий' : 'Добавить комментарий'}
  </button>
</form>



      {comments.length > 0 ? (
        <ul className="space-y-4">
          {comments.map((comment) => (
            <React.Fragment key={comment._id}>
              <li className="bg-white shadow rounded p-4">
                <div className="flex justify-between items-center">
                  <p>{comment.text}</p>
                  <div>
                    <button onClick={() => handleEdit(comment)} className="text-blue-500 mr-2">
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button onClick={() => handleDelete(comment._id)} className="text-red-500">
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </li>
              {editingComment && editingComment._id === comment._id && (
                <li className="bg-gray-100 p-4 rounded">
                  <textarea
                    className="w-full p-2 border rounded mb-2"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    rows="3"
                  />
                  <button onClick={handleSubmit} className="bg-green-500 text-white px-4 py-2 rounded mr-2 hover:bg-green-600 transition duration-200">
                    Сохранить
                  </button>
                  <button onClick={handleCancelEdit} className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-200">
                    Отменить
                  </button>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500">Нет комментариев для отображения.</p>
      )}
      {comments.length > 0 && (
        <div className="flex justify-center mt-6">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
              <button
                key={pageNum}
                onClick={() => handlePageChange(pageNum)}
                className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                  ${pageNum === page
                    ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                  }
                  ${pageNum === 1 ? 'rounded-l-md' : ''}
                  ${pageNum === totalPages ? 'rounded-r-md' : ''}
                `}
              >
                {pageNum}
              </button>
            ))}
          </nav>
        </div>
      )}
    </div>
  );
}

export default Comments;